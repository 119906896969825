/**
* Input component for Search input 
*/
import React, { useEffect, useState } from 'react'
import {  TextField, InputAdornment } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Search as SearchIcon } from '@material-ui/icons'
import { useDebounce } from './utils'


interface SerachProps {
	placeholder?: string;
	design?: string;
	searchInputValue?:string;
	/** changed with a debounce */
	onChange?: (val: string) => void;
}

export default function Search({ placeholder, onChange, design, searchInputValue }: SerachProps) {
	const [value, setValue] = useState("")
	const debounce = useDebounce(value, 500)

	useEffect(()=>{
		
		searchInputValue && setValue(searchInputValue)
	},[searchInputValue])
	
	useEffect(() => {
		onChange && onChange(debounce)
	}, [debounce])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)
	return <TextFieldRounded
			style={design && design == "half" ?	{ width: "50%" } : { width: "100%" }}
	        placeholder={placeholder || "Search"}
	        variant="outlined"
	        value={value}
	        onChange={handleInputChange}
	        InputProps={{ startAdornment: ( <InputAdornment position="start">
	              <SearchIcon />
	            </InputAdornment>
	          ),
	        }}
	      />
}


const TextFieldRounded = styled(TextField)((props: any) => ({
  '& > .MuiOutlinedInput-root': {
    borderRadius: '15px',
	background: '#FFFFFF',
  },
  '& > div > input': {
    paddingTop: '14px',
    paddingBottom: '14px',
	'&::placeholder': {
		color: '#484747 !important',
		opacity: '1 !important',
	}
  },
	[props.theme.breakpoints.down('sm')]: {
		width: '100% !important',
	},
}))


