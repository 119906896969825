Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelUserName = "User name"
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
// Customizable Area Start
exports.passwordError="Pasword Must contains alteast 1 upper case ,1 lower case,1 number , 1 special character";
exports.lengthFirstName = "FirstName must contain only Alphabets and max length not exceeded 50 characters";
exports.lengthLastName = "LastName must contain only Alphabets and max length not exceeded 50 characters";
exports.lengthLocation = "Location must contain only Alphabets and max length not exceeded 50 characters";
exports.accountsAPiEndPointWeb ="account_block/accounts";
exports.preferencesListEndpoint = "/preferences"
exports.termsAndConditionEndpoint = "account_block/static_pages/term_condition"
exports.googleSignupEndpoint = "/account_block/accounts"
exports.linkedinSignupEndpoint = "/account_block/accounts/linkedin"
// Customizable Area End
