import React from "react";

// Customizable Area Start
import { 
  Button, 
  Divider, 
  InputAdornment, 
  MenuItem, 
  Paper, 
  Tabs, 
  Tab, 
  Typography, 
  CircularProgress } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik, useField, useFormikContext } from 'formik'
import OverlayLoader from '../../../components/src/Loader.web'

import { iconLocation, iconAdd, iconUpload, logoIkea, logoCowi, logoWashington } from '../../JobListing2/src/assets'
import FilterItems from '../../filteritems/src/Filteritems.web'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import RoundedButton from '../../../components/src/RoundedButton.web'
import PostCard, { CommonActions, Image } from '../../../components/src/PostCard.web'
import Search from '../../../components/src/Search.web'
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import MarketplaceTopbar from '../../../components/src/MarketplaceTopbar.web'
import FileDrop from '../../../components/src/FileDrop.web'
import SeeMore from '../../../components/src/SeeMore.web'
import Flex from '../../../components/src/Flex.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import Maps from '../../maps/src/Maps.web'
// import { EnquireFormValues } from './types'

interface ApplyFormValues {
  name: string;
  email: string;
  phone_number: string;
  location: string|null;
  comment: string;
  file: File | string;
}
// Customizable Area End

import EnquireServiceController, {
  Props,
  configJSON
} from "./EnquireServiceController";

export default class EnquireService extends EnquireServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  

  EnquireServiceModal() {
    const user = this.context
    let service_id_1 = `${user.first_name.slice(0,3)}${user.last_name.slice(0, 2)}`.toUpperCase()
    let service_id_2 = this.state.randomServiceId || '-----'
    if(this.state.service?.service_unique_id) {
      service_id_1 = this.state.service?.service_unique_id.split('-')[0]
      service_id_2 = this.state.service?.service_unique_id.split('-')[1]
    }
    let initialValues: any = {
      service_id_1,
      service_id_2,
      service_name: this.state.service?.service_name||'',
      name:   '',
      email: '',
      phone_number: '',
      location: '',
      description: '',
      comment:'',
      attachments:'',
    }
    console.log({EnquireService: this.state.service, })
    const isEditPage = !!this.props.navigation.getParam('id')
    
    return <Formik enableReinitialize values initialValues={initialValues} validationSchema={this.enquireFormSchema} onSubmit={this.handleEnquireServiceSubmit}>
      <FormModal headerTitle={<b>ENQUIRE</b>}  onClose={this.hideModal}>
        <FormikLogger />
        <InputRow label="Service ID" input={
                 <IDWrapper>
                    <Flex1>
                      <MInput name="service_id_1" disabled placeholder="Service ID" />
                    </Flex1>
                    <Flex1>
                      <MInput name="service_id_2" disabled placeholder="Service ID" />
                    </Flex1>
                  </IDWrapper>
              } />
        <InputRow label="Service Name" input={
              <MInput name="service_name" disabled placeholder="Enter service name" />
        } />
        <InputRow label="Name*" input={
              <MInput name="name"  placeholder="Enter full name" />
        } />
        <InputRow label="Email Id*" input={
              <MInput name="email"  placeholder="Enter email id" />
        } />
        <InputRow label="Phone Number*" input={
              <MInput name="phone_number"  placeholder="Enter phone number" />
        } />
        {/* @ts-ignore */}
        <InputRow label="Location*" input={<Maps input {...this.props} />} />
        <InputRow label="Description*" input={
              <MCountedTextArea name="description" placeholder="Service description" />
        } />
                <InputRow label="Comment" input={
              <MCountedTextArea name="comment" placeholder="Write a Comment" />
        } />
        <div style={{ margin: '10px 0' }}>
          <UploadButton
              maxTotalSize={10*1024*1024} 
              name="attachments" 
              multiple={false} 
              label="Upload Attachment"
            
              />
          </div>
        <OverlayLoader loading={!!this.state.submittingForm} />
      </FormModal>
    </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const tabLabels = [
      configJSON.labelMyServices, 
      configJSON.labelMyEvents, 
      configJSON.labelMyProducts, 
      configJSON.labelMyServices, 
      configJSON.labelMyCourses,
    ]
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.EnquireServiceModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

EnquireServiceController.contextType = UserContext

function FormikLogger(){
  const formikContext = useFormikContext()
  console.log({formikContext}) 
  return<></>
}

const UploadButton = (props: any) => {
  const [field, meta, helpers] = useField(props)
  const [filename, setFilename] = React.useState(props.filename||"")
  React.useEffect(() => {
    setFilename(props.filename)
  }, [props.filename])
  const [validationError, setValidationError] = React.useState("")
  const inputRef = React.useRef<HTMLInputElement >(null)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true)
    const file = e.currentTarget.files && Array.from(e.currentTarget.files)
    if(!file) return
    const totalSize = file.map((f: any)=>f.size).reduce((a: number,b: number)=>a+b)
  console.log("totalFileSize", {totalSize})
    if(props.maxSize&&totalSize>props.maxSize) {
      setValidationError(props.maxSizeError || `Cannot upload document more than 20Mb size`)
      return 
    }
    helpers.setValue(file)
    setFilename(file.map((f:any) => f.name).join(', '))
  }

  const onClick = () => inputRef?.current && inputRef?.current?.click()

  return <label>
            <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>{props.label}</RoundedButton> 
            <input multiple={props.multiple} type="file" accept={props.filetype || "*"} onChange={onChange} ref={inputRef} name={props.name} style={{ display: 'none' }} />
            <div>{filename}</div>

            {meta.touched && (meta.error || validationError) && <Typography color="error">{meta.error || validationError}</Typography>}
         </label>
}

const dollarFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }
}

const rupeeFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">₹</InputAdornment>
  }
}

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})
const Flex1 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }
})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const RoundedButtonBase = styled(Button)({
  backgroundColor: '#009c05',
  color: 'white',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})


const recommendedServices = [{
  title: 'Lecturer Sustainable Finance',
  company: 'University of Washington',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoWashington,
}, {
  title: 'Sustainability Expert',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoIkea,
}, {
  title: 'Senior Consultant and Project Manager',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoCowi,
},]

interface RJCardProps {
  title: string;
  company: string;
  location: string;
  created_at: string;
  logo: any;
}

const RJCard = (props: RJCardProps) => {
  return <RJCardWrapper>
    <div>
      <img src={props.logo} />
    </div>
    <div>
      <RJCardTitle variant="h6">{props.title}</RJCardTitle>
      <RJContent>
        <span>{props.company}</span>
        <Divider orientation="vertical" />
        <span><img src={iconLocation} width="20" style={{marginBottom: '-4px', marginRight: '5px'}} />{props.location}</span>
        <Divider orientation="vertical" />
        <span>{props.created_at}</span>
      </RJContent>
    </div>
  </RJCardWrapper>
}

const RJCardTitle = styled(Typography)({

})

const RJContent = styled('div')({
  height: '20px',
  display: 'flex',
  gap: '15px',
  color: '#444'
})

const RJCardWrapper = styled('div')({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '25px',
})

const RecommendedServices = () => {
  return <RJWrapper>
    <RJHeader><b>Services</b> you may be interested in</RJHeader>
    <Divider />
    {recommendedServices.map((rj, idx) => <React.Fragment key={idx}>
        <RJCard {...rj} />
        <Divider />
      </React.Fragment>)}
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <RoundedButton>SEE MORE</RoundedButton>
    </div>
  </RJWrapper>
}

const RJWrapper = styled('div')({
  borderRadius: '2px',
  margin: '20px 0px',
  background: 'white',
  padding: '10px 0px',
})

const RJHeader = styled('div')({
  borderRadius: '15px',
  border: '3px solid #eee',
  padding: '20px 20px',
  margin: '20px 10px',
  color: '#444'
}) 
// Customizable Area End
