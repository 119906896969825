import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet
} from "react-native";
import { Avatar, Box, Button,  CircularProgress, FormControl, Paper,  TextField, Typography } from '@material-ui/core';
import { CreateCSSProperties, styled } from '@material-ui/styles';
import { CameraAlt, Videocam, InsertDriveFile } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FieldEffect, FormikEffect } from '../../../components/src/FormikEffect.web'

import scale, { verticalScale } from "../../../components/src/Scale";
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import { Scrollbars } from 'react-custom-scrollbars-2';
import OverlayLoader from '../../../components/src/Loader.web'
//@ts-ignore
// import { Dropdown } from "react-native-material-dropdown";
import PostCreationController from "./PostCreationCommonController";
import Scale from "../../../components/src/Scale";
import UserContext from "../../../components/src/UserProvider";
import FilePreview, { PreviewRow } from "../../../components/src/FilePreview.web";
import SearchSelect, {Checkbox} from "../../../components/src/SearchSelect.web";
import { edit, PhotoIcon, VideoIcon, DocIcon, CloseIcon, AvatarIcon } from "../src/assets";
import {EndAdornment} from "../../../components/src/SelectInput.web"
// @ts-ignore
import * as classes from './PostCreation.web.module.css'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;




// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostCreation extends PostCreationController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
   // Customizable Area Start
   
    // Customizable Area End
    
  render() {
    // Customizable Area Start
    let community_ids = []
    if(this.state.currentPost?.community_details){
      community_ids = this.state.currentPost?.community_details.data.map((com: any)=>parseInt(com.id))
    }
    const initialValues = {
      ...this.initialValues, 
      title: this.state.currentPost?.name||'',
      community_ids 
    }
    let initCommunities = [] // commmunities for edit a post, as it may not already be present
    if(this.state.currentPost)
      initCommunities = this.state.currentPost?.community_details.data.map((com: any)=> ({ id: parseInt(com.id), ...(com.attributes) }))
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
          { this.state.showModal && <Wrapper>
              <Modal>
                <CloseButton onClick={this.handleCloseModal}>
                  <img src={CloseIcon} width="26px" />
                </CloseButton>
                <ModalBody>
                  <div>
                    <Avatar src={this.context.profile_pic || AvatarIcon}></Avatar>
                  </div>
                  <Formik 
                    initialValues={initialValues} 
                    validationSchema={this.validationSchema}
                    onSubmit={this.createNewPost}
                    enableReinitialize
                  >

                  <RightFormWrapper translate="no">
                    <div style={{position: 'relative', width: '100%'}}>
                      <FormikTextField
                        label="Title"
                        name="title"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ width: '100%' }}>
                      <FieldEffect name="community_ids" onChange={this.handleCommunityChangeEffect} />
                      <SearchSelect
                        hasMore={!!this.state.communityPagination?.next_page} 
                        nextPage={this.paginateCommunitiesOptions} 
                        loading={(!!this.state.communitySearchCallId) || (!!this.state.communityPaginateCallId)} 
                        onSearch={this.handleCommunitySearch} 
                        allItems={this.state.communities} 
                        items={this.state.communities}
                        preCacheItems={initCommunities} 
                        name="community_ids"
                        label={"Select Community"}
                        placeholder="Select Community"
                        SelectItem={SelectItemCommunity}
                        getOptionValue={(community: any) => community.name} 
                      />
                    </div>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      loading={!this.state.categories?.length}
                      options={this.state.categoriesOptions}
                      value={this.state.selectedCategory}
                      getOptionLabel={(option: any) => option.name}
                      filterSelectedOptions
                      popupIcon={<>
                        {!this.state.allSubCategories?.length && <CircularProgress color="primary" size={20} />}
                        {<EndAdornment style={{position: 'relative'}} />}
                      </>}
                      renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </>
                        )}
                      onChange={this.handleCategoryChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Category"
                          placeholder=" "
                          InputProps={{
                            ...params.InputProps,
                            style: { paddingRight: '12px',  },
                            // endAdornment: (<div>
                            //   <EndAdornment style={{position:'relative'}} />
                            // </div>),
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      onChange={this.handleSubCategoryChange}
                      value={this.state.selectedSubCategory}
                      loading={!this.state.allSubCategories?.length}
                      options={this.state.subCategories}
                      getOptionLabel={(option: any) => option.name}
                      filterSelectedOptions
                      popupIcon={<>
                        {!this.state.allSubCategories?.length && <CircularProgress color="primary" size={20} />}
                        {<EndAdornment style={{position: 'relative'}} />}
                      </>}
                      renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </>
                        )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                        
                          InputProps={{
                            ...params.InputProps,
                            style: { paddingRight: '12px',  },
                            // endAdornment: (<>
                            //   {!this.state.allSubCategories?.length && <CircularProgress color="primary" size={20} />}
                            //   {<EndAdornment style={{position: 'relative'}} />}
                            // </>),
                          }}
                          variant="outlined"
                          label="Select Sub-Category"
                          placeholder=" "
                        />
                      )}
                    />
                    <div style={{position:'relative', width:'100%'}}>
                      <TextField
                        style={{ width: '100%' }}
                        id="outlined-multiline-static"
                        label="Write a post"
                        multiline
                        rows={6}
                        variant="outlined"
                        value={this.state.postText}
                        error={this.state.postText.length> 1000}
                        onChange={this.handlePostTextChange}
                        data-test-id = "changepostTest"
                      />      
                      {this.state.showSuggetion&& this.state.tagPostData.length > 0 && (
                        <DropBox >
                       {this.state.tagPostData.map((suggestion, index) => (
                          <SuggestionBox
                            key={index}
                            data-test-id = "handlesuggestion"
                            onClick={(e) => {      
                             this.handleSuggestionClick(suggestion);
                            }}                        
                          >
                            {suggestion?.user_name}
                          </SuggestionBox>
                        ))}
                         </DropBox>
                         )
                       }
                      <CharCounter color="textSecondary" align="right">( {this.state.postText.length}/1000 Characters )</CharCounter>
                    </div>
                    <div style={{marginBottom: '5px', marginTop: '-25px'}}>
                      <Typography color="error">{this.state.postText.length > 1000 && "Max character limit reached."}</Typography>
                    </div>
                    <Typography>For an ideal image representation, pixel dimensions should be 1080*550</Typography>
                    <PreviewRow>
                      {this.state.selectedFiles?.map((file: any) => <FilePreview onRemove={this.handleFileRemove} key={file.name} file={file} />)}
                      {this.state.currentPost && 
                        this.state.currentPost.images_and_videos?.map?.(
                          (file: any) => <FilePreview 
                              onRemove={this.handleAttachmentRemove} 
                              key={file.id} 
                              file={file.url}
                              filetype={file.type}
                              filename={file.filename} 
                            />
                        )
                      }
                    </PreviewRow>
                    <Typography color="error">
                      {this.state.fileSizeWarning}
                    </Typography>
                    <ModalActions>
                      <div>
                        <Button
                          disableElevation
                          component="label"
                        >
                          <img src={PhotoIcon} width="26" />
                          <input
                            accept="image/png, image/jpeg"
                            id="t1"
                            type="file"
                            hidden
                            multiple
                            data-test-id='handleChange'
                            onChange={this.handleFileChange}
                          />
                        </Button>
                        <Button
                          component="label"
                          disableElevation
                        >
                          <img src={VideoIcon} width="26" />
                          <input
                            accept="video/mp4,video/x-m4v"
                            type="file"
                            hidden
                            multiple
                            onChange={this.handleFileChange}
                          />
                        </Button>
                        <Button
                          component="label"
                          disableElevation
                        >
                          <img src={DocIcon} width="22" />
                          <input
                            accept="application/msword, application/pdf"
                            type="file"
                            hidden
                            multiple
                            onChange={this.handleFileChange}
                          />
                        </Button>
                      </div>
                    <PostButton type="submit" disabled={!this.isPostButtonEnabled()} variant="contained" disableElevation>
                      {this.state.currentPost?"Update":"Post"}
                    </PostButton>
                  </ModalActions>
                </RightFormWrapper>
                </Formik>
              </ModalBody>
              <OverlayLoader loading={this.state.submittingPost || this.state.loading} />
            </Modal>
            </Wrapper>
          }
          </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FormikTextField = (props: any)=>{
  const [field, meta, helpers] = useField(props.name)
  return <><TextField
          error={!!(meta.touched && meta.error)}
          style={{ width: '100%' }}
          variant="outlined"
          {...field}
          {...props}
    />
    <Typography color="error">{meta.touched && meta.error}</Typography>
    </>
}

PostCreationController.contextType = UserContext
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
  }
});

export const SelectItemCommunity = ({ item, value }: {item: any, value: any})=>{
  return <>
    <Checkbox checked={value?.indexOf?.(item.id) > -1} />
    <SelectItemCommunityText >{item.name}</SelectItemCommunityText>
  </>
}
const SelectItemCommunityText = styled('span')({
  overflow: 'hidden', 
  textOverflow: 'ellipsis'
})






const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Modal = styled(Paper)({
  width: '100%',
  margin: '0 33px',
  maxWidth: '600px',
  minHeight: '150px',
  maxHeight: 'max(90vh, calc(100vh - 100px))',
  padding: '20px 10px',
  position: 'relative',
  '@media screen and (max-width:768px)':{
    maxWidth: '400px',
  },
  '@media screen and  (max-width:350px)':{
    maxWidth: '235px',
  },
  '@media screen and  (max-width:480px)':{
    maxWidth: '300px',
  },
})

const ModalBody = styled('div')({
  display: 'flex',
  gap: '15px',
  maxHeight: 'inherit',
  overflow: 'auto',
  overflowX: 'hidden', 
  '@media screen and (max-width:450px)':{
    flexDirection:'column'
  }
})

const ModalActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '20px 10px 0 10px',
  '@media screen and (max-width:450px)':{
    flexDirection:'column'
  }
})

const RightFormWrapper = styled(Form)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
  padding: '10px 0',
  overflowX: 'hidden',
  paddingRight: '10px',
})

const FormControlStyled = styled(FormControl)({
  width: '100%'
})

const CloseButton = styled(Button)({
  position: 'absolute',
  top: '-10px',
  right: '-46px'
})

const CharCounter = styled(Typography)({
  position: 'absolute',
  bottom: 0,
  right: '10px',
})

const PostButton = styled(Button)({
  background: '#009c20',
  color: '#FFF',
  '&:hover': {
    background: '#009c20',
    opacity: 0.9
  },
})

const DropBox = styled(Box)(
  {
   position: 'absolute',
        top: '20%', 
        margin: '3rem 1rem',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        width: '95%',
        zIndex: 1000,
        maxHeight: '150px',
        overflowY: 'auto',
  })

  const SuggestionBox = styled(Box)(
    {
      padding: "8px",
      borderBottom: "1px solid #ddd",
      cursor: "pointer",
    }
  )


// Customizable Area End
