import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import React,{ Context } from 'react'
import * as yup from 'yup'
import { getStorageData } from '../../../framework/src/Utilities';
import { toast } from 'react-toastify';
import UserContext, { User } from "../../../components/src/UserProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  selectedCategory: unknown,
  loading: boolean,
  cfNavActivate: string,
  tableHeader:(string | { name: string; children: (string | { name: string; children: string[] })[] })[],
  CfStationaryCombustionTableData: any,
  cfStationaryCombustionForm: boolean,
  isEditCfStationaryCombustionForm: boolean,
  editDataCfStationaryCombustionForm: any,
  editCfStationaryCombustionDataIndex: any,
  fuelDropdownData: string[],
  fuelDropdownDataEfExist: string[]
  unitsDropdownData: any[],
  stationaryunitsDropdownData:any[],
  facilityIdDropdownData: string[],
  units_of_weightDropdown:string[],
  cfPurchasedElectricityForm: boolean,
  isEditCfPurchasedElectricityForm: boolean,
  cfPurchasedElectricityTableData: any,
  editDataCfPurchasedElectricityForm: any,
  editCfPurchasedElectricityDataIndex: any,

  cfTransportationTableData: any,
  cfTransportationForm: boolean,
  isEditCfTransportationForm: boolean,
  editDataCfTransportationForm: any,
  editCfTransportationDataIndex: any,
  transportDropdownData: any,

  cfMobileCombustionTableData: any,
  cfMobileCombustionForm: boolean,
  isEditCfMobileCombustionForm: boolean,
  editDataCfMobileCombustionForm: any,
  editCfMobileCombustionDataIndex: any,
  mobileCombustionDropdownData: any,

  cfRefrigerentsTableData: any,
  cfRefrigerentsForm: boolean,
  isEditCfRefrigerentsForm: boolean,
  editDataCfRefrigerentsForm: any,
  editCfRefrigerentsDataIndex: any,
  refrigerentsDropdownData: any,
 
 cfWasteTableData: any,
 cfWasteForm: boolean,
 isEditCfWasteForm: boolean,
 editCfDataWasteForm: any,
 editWasteDataIndex: any,
 wasteDisposalMethodDropdown: string[],
 
 cfOffsetsTableData: any,
 cfOffsetsForm: boolean,
 isEditCfOffsetsForm: boolean,
 editCfDataOffsetsForm: any,
 editOffsetsDataIndex: any,
 offsetsScopeDropdownData:string[]
 
 cfPurchaseCommodityTableData: any,
 cfPurchaseCommodityForm: boolean,
 isEditCfPurchaseCommodityForm: boolean,
 editCfDataPurchaseCommodityForm: any,
 editPurchaseCommodityDataIndex: any,
 purchaseCommodityDropdownData: string[],

 cfFireSuppresionTableData: any,
 cfFireSuppresionForm: boolean,
 isEditCfFireSuppresionForm: boolean,
 editCfDataFireSuppresionForm: any,
 editFireSuppresionDataIndex: any,
 //FireSuppresionDropdownData:string[] 

 cfSteamTableData: any,
 cfSteamForm: boolean,
 isEditCfSteamForm: boolean,
 editCfDataSteamForm: any,
 editSteamDataIndex: any,
// SteamDropdownData:string[] 

 cfBoundaryQuestionsTableData: any,
 cfBoundaryQuestionsForm: boolean,
 isEditCfBoundaryQuestionsForm: boolean,
 editCfDataBoundaryQuestionsForm: any,
 editBoundaryQuestionsDataIndex: any,
 //BoundaryQuestionsDropdownData:string[] 

  yearsDropdownData: string[],
  countriesDropdownData: string[],
  typesOfScope:{ name: string; key: string}[],
  scopeFormTitle: string,
  saveAsDraftData: any,
  total_pages: number,
  current_page: number,
  
  scDeleteId: number[],
  mcDeleteId: number[],
  rfDeleteID: number[],
  peDeleteId: number[],
  tsDeleteId: number[],
  fsDeleteId: number[],
  steamDeleteId:number[],
  offsetsDeleteID:number[],
  wasteDeletedID:number[],
  isDeleteModelOpen: boolean,
  deleteModelIndex: any,
  isDraftModelOpen: boolean,
  isSaveModelOpen: boolean

  SaveDraftObserver: boolean,

  isViewForm: boolean,
  isReadable: boolean,
  startIndex: number;

  submitDataLoader: boolean,
  pdfUrl:string,
  fuelListStationary: any,
  fuelSourceListMobile: any,
  vehicleTypeListMobile: any,
  activityTypeList: any,
  vehicleTypeListTransport: any,
  filterPurchasedEmissionFactor: any,
  unitsOfMeasurement: any
  newfuelDropDown :any,
  selectedFuel : any,
  fuelSourceUnits :any,
  fuelTypeListMobile:any,
  gasTypeFireSuppressionDropdown : any,
  steamFuelTypes : string[],
  offsetsScops :string[],
  wasteMaterialDropdwon:string[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StakeholderProfileFormController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  static contextType?: Context<User> = UserContext;

  getstationaryCombustionCallId = ''
  getPurchasedElectricityCallId = ''
  getTransportationCallId = ''
  getmobileCombustionCallId = ''
  getrefrigerentsCallId = ''
  getFireSuppressionCallid = ''
  getSteamCallId =''
  getOffsetsCallId=''
  getWasteCallId=''

  token=''


  draftDataTransportationsCallID=''
  draftDataPeCallID=''
  draftDataRfCallID=''
  draftDataMcCallID=''
  draftDataScCallID=''
  draftDataSFsCallID=''
  draftDataStmCallID=''
  draftDataOffsetsCallID=''
  draftDataWasteCallID=''

  submitDataScCallID=''
  submitDataMcCallID=''
  submitDataRfCallID=''
  submitDataPeCallID=''
  submitDataTsCallID=''
  submitDataFsCallID=''
  submitDataStmCallID=''
  submitDataOffsetsCallID=''
  submitDataWasteCallID=''

  path = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      selectedCategory: 'Test',
      txtSavedValue:'',
      // Customizable Area Start
      pdfUrl: '',
      scopeFormTitle: '',
      startIndex: 0,
      selectedFuel : {},
      typesOfScope : [
        { name: 'S1-STATIONARY COMBUSTION', key: 's1_stationaryCombustion' },
        { name: 'S1-Mobile Combustion', key: 's1_mobileCombustion' },
        { name: 'S1-Refrigerants', key: 's1_refrigerants' },
        { name: 'S2-Purchased Electricity', key: 's2_purchasedElectricity' },
        { name: 'S3-Transportation', key: 's3_transportation' },
        { name: 'S1-Firesuppression', key: 's1_fireSuppression' },
        { name: 'S2-STEAM', key: 's2_steam' },
        { name: 'S3-WASTE', key: 's3_waste' },
        { name: 'OFFSETS', key: 'offsets' },
        { name: 'PURCHASE COMMODITY', key: 'purchase_commodity' },
        { name: 'BOUNDARY QUESTIONS', key: 'boundary_equations' },

      ],
      loading:true,
      cfNavActivate: "s1_stationaryCombustion",
      tableHeader:[],

      CfStationaryCombustionTableData:[],
      cfStationaryCombustionForm: false,
      isEditCfStationaryCombustionForm: false,
      editDataCfStationaryCombustionForm: {},
      editCfStationaryCombustionDataIndex: {
        ind : null,
        key : null,
      },
      
      cfPurchasedElectricityTableData: [],
      cfPurchasedElectricityForm: false,
      isEditCfPurchasedElectricityForm: false,
      editDataCfPurchasedElectricityForm: {},
      editCfPurchasedElectricityDataIndex: {
        ind: null,
        key: null,
      },
     
      cfTransportationTableData: [],
      cfTransportationForm: false,
      isEditCfTransportationForm: false,
      editDataCfTransportationForm: {},
      editCfTransportationDataIndex: {
        ind: null,
        key: null,
      },

      cfWasteTableData: [],
      cfWasteForm: false,
      isEditCfWasteForm: false,
      editCfDataWasteForm: {},
      editWasteDataIndex: {
       ind : null,
       key : null,
     },

     cfOffsetsTableData: [],
     cfOffsetsForm: false,
     isEditCfOffsetsForm: false,
     editCfDataOffsetsForm: {},
     editOffsetsDataIndex: {ind:null, key:null},

     cfPurchaseCommodityTableData: [],
     cfPurchaseCommodityForm: false,
     isEditCfPurchaseCommodityForm: false,
     editCfDataPurchaseCommodityForm: {},
     editPurchaseCommodityDataIndex: {ind:null, key:null},

     cfFireSuppresionTableData: [],
     cfFireSuppresionForm: false,
     isEditCfFireSuppresionForm: false,
     editCfDataFireSuppresionForm: {},
     editFireSuppresionDataIndex: {ind:null, key:null},
     
     cfSteamTableData: [],
     cfSteamForm: false,
     isEditCfSteamForm: false,
     editCfDataSteamForm: {},
     editSteamDataIndex: {ind:null, key:null},
     

    cfBoundaryQuestionsTableData: [],
    cfBoundaryQuestionsForm: false,
    isEditCfBoundaryQuestionsForm: false,
    editCfDataBoundaryQuestionsForm: {},
    editBoundaryQuestionsDataIndex: {ind:null, key:null},
  

      transportDropdownData: {
        category: ['Business Travel', 'Employee Commute', 'Upstream T&D','Capital Goods','Fuel and Energy Realted Activities(Not Included In Scope 1 Or 2)','Waste generated in Operations','Upstream Leased Assests','Downsteam Transportation and Distribution','Processing of Sold Products','Downstream Leases Assets','Franchises','Investments'],
        emission_factor_dataset: ['Custom emission factor', 'UK DEFRA', 'US EPA'],
        mode_of_transport: ['Air', 'Bus', 'Car', 'Ferry', 'Rail'],
        activity_type: ['Distance', 'Passenger Distance', 'Vehicle Distance', 'Weight Distance'],
        activity_type_cef: ['Example S3'],
        vehicle_type: [
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Air', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Bus', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Car', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Ferry', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Rail', value: '#REF!' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Air', value: 'Air Travel - Long Haul (>= 2300 miles)' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Air', value: 'Air Travel - Medium Haul (>= 300 miles, < 2300 miles)' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Air', value: 'Air Travel - Short Haul (< 300 miles)' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Bus', value: 'Bus' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Rail', value: 'Commuter Rail D' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Rail', value: 'Intercity Rail (i.e. Amtrak) C' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Rail', value: 'Transit Rail (i.e. Subway, Tram) E' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Car', value: 'Light-Duty Truck B' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Car', value: 'Motorcycle' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Car', value: 'Passenger Car A' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Ferry', value: '#REF!' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Diesel' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Petrol' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Hybrid' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - CNG' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - LPG' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Plug-in Hybrid Electric Vehicle' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Battery Electric Vehicle' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Unknown' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Motorbike' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Taxi - regular' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Domestic, to/from UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Short-haul, to/from UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Short-haul, to/from UK - Economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Short-haul, to/from UK - Business class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Premium economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Business class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - First class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Premium economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Business class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - First class_RF' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Bus', value: 'Local Bus' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Rail', value: 'National rail' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Rail', value: 'International rail' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Rail', value: 'Light rail and tram' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Ferry', value: 'Ferry - Average (all passenger)' },
        ],
        units_of_measurement: ['km', 'mile', 'nautical mile'],
        emission_calculation_methodology: ["Average data (Physical unit) method",
          "Hybrid method",
          "Supplier-specific calculation method",
          "Fuel-based method",
          "Activity-based method",
          "Gamma Generalised Linear Model (Gamma GLM)",
          "Bottom-up model",
          "Spend-based method"]
      },

      cfMobileCombustionTableData: [],
      cfMobileCombustionForm: false,
      isEditCfMobileCombustionForm: false,
      editDataCfMobileCombustionForm: {},
      editCfMobileCombustionDataIndex: {
        ind: null,
        key: null,
      },
      mobileCombustionDropdownData: {
        activity_type: ['Custom emission factor', 'Distance Activity', 'Fuel Use'],
        fuel_source: [
          { vehicle_type: 'Passenger Cars', value: 'Gasoline' },
          { vehicle_type: 'Passenger Cars', value: 'Diesel' },
          { vehicle_type: 'Light-Duty Trucks', value: 'Gasoline' },
          { vehicle_type: 'Light-Duty Trucks', value: 'Diesel' },
          { vehicle_type: 'Light-Duty Trucks', value: 'Methanol' },
          { vehicle_type: 'Light-Duty Trucks', value: 'Ethanol' },
          { vehicle_type: 'Light-Duty Trucks', value: 'CNG' },
          { vehicle_type: 'Light-Duty Trucks', value: 'LPG' },
          { vehicle_type: 'Light-Duty Trucks', value: 'Biodiesel' },
          { vehicle_type: 'Medium-Duty Vehicles', value: 'Gasoline' },
          { vehicle_type: 'Medium-Duty Vehicles', value: 'Diesel' },
          { vehicle_type: 'Heavy-Duty Vehicles', value: 'Gasoline' },
          { vehicle_type: 'Heavy-Duty Vehicles', value: 'Diesel' },
          { vehicle_type: 'Motorcycles', value: 'Gasoline' },
          { vehicle_type: 'Light-Duty Cars', value: 'Gasoline' },
          { vehicle_type: 'Light-Duty Cars', value: 'Methanol' },
          { vehicle_type: 'Light-Duty Cars', value: 'Ethanol' },
          { vehicle_type: 'Light-Duty Cars', value: 'CNG' },
          { vehicle_type: 'Light-Duty Cars', value: 'LPG' },
          { vehicle_type: 'Light-Duty Cars', value: 'Biodiesel' },
          { vehicle_type: 'Medium-Duty Truck', value: 'Gasoline' },
          { vehicle_type: 'Medium-Duty Truck', value: 'Methanol' },
          { vehicle_type: 'Medium-Duty Truck', value: 'Ethanol' },
          { vehicle_type: 'Medium-Duty Truck', value: 'CNG' },
          { vehicle_type: 'Medium-Duty Truck', value: 'LPG' },
          { vehicle_type: 'Medium-Duty Truck', value: 'Biodiesel' },
          { vehicle_type: 'Heavy-Duty Trucks', value: 'Methanol' },
          { vehicle_type: 'Heavy-Duty Trucks', value: 'CNG' },
          { vehicle_type: 'Heavy-Duty Trucks', value: 'LPG' },
          { vehicle_type: 'Heavy-Duty Trucks', value: 'LNG' },
          { vehicle_type: 'Heavy-Duty Trucks', value: 'Biodiesel' },
          { vehicle_type: 'Buses', value: 'Methanol' },
          { vehicle_type: 'Buses', value: 'Ethanol' },
          { vehicle_type: 'Buses', value: 'CNG' },
          { vehicle_type: 'Buses', value: 'LPG' },
          { vehicle_type: 'Buses', value: 'LNG' },
          { vehicle_type: 'Buses', value: 'Biodiesel' },
          { vehicle_type: 'Ships and Boats', value: 'Residual Fuel Oil' },
          { vehicle_type: 'Ships and Boats', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Ships and Boats', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Ships and Boats', value: 'Diesel' },
          { vehicle_type: 'Locomotives', value: 'Diesel' },
          { vehicle_type: 'Aircraft', value: 'Jet Fuel' },
          { vehicle_type: 'Aircraft', value: 'Aviation Gasoline' },
          { vehicle_type: 'Agricultural Equipment', value: 'Diesel' },
          { vehicle_type: 'Agricultural Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Agricultural Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Agricultural Equipment', value: 'Gasoline Off-Road Trucks' },
          { vehicle_type: 'Agricultural Equipment', value: 'Diesel Equipment' },
          { vehicle_type: 'Agricultural Equipment', value: 'Diesel Off-Road Trucks' },
          { vehicle_type: 'Agricultural Equipment', value: 'LPG' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Agricultural Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Agricultural Equipment', value: 'Gasoline Off-Road Trucks' },
          { vehicle_type: 'Agricultural Equipment', value: 'Diesel Equipment' },
          { vehicle_type: 'Agricultural Equipment', value: 'Diesel Off-Road Trucks' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'Gasoline Off-Road Trucks' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'Diesel Equipment' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'Diesel Off-Road Trucks' },
          { vehicle_type: 'Construction/Mining Equipment', value: 'LPG' },
          { vehicle_type: 'Lawn and Garden Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Lawn and Garden Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Lawn and Garden Equipment', value: 'Diesel' },
          { vehicle_type: 'Lawn and Garden Equipment', value: 'LPG' },
          { vehicle_type: 'Airport Equipment', value: 'Gasoline' },
          { vehicle_type: 'Airport Equipment', value: 'Diesel' },
          { vehicle_type: 'Airport Equipment', value: 'LPG' },
          { vehicle_type: 'Industrial/Commercial Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Industrial/Commercial Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Industrial/Commercial Equipment', value: 'Diesel' },
          { vehicle_type: 'Industrial/Commercial Equipment', value: 'LPG' },
          { vehicle_type: 'Logging Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Logging Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Logging Equipment', value: 'Diesel' },
          { vehicle_type: 'Railroad Equipment', value: 'Gasoline' },
          { vehicle_type: 'Railroad Equipment', value: 'Diesel' },
          { vehicle_type: 'Railroad Equipment', value: 'LPG' },
          { vehicle_type: 'Recreational Equipment', value: 'Gasoline (2 stroke)' },
          { vehicle_type: 'Recreational Equipment', value: 'Gasoline (4 stroke)' },
          { vehicle_type: 'Recreational Equipment', value: 'Diesel' },
          { vehicle_type: 'Recreational Equipment', value: 'LPG' },

        ],
        fuel_source_cef:[
          'Example S1 - Mobile'
        ],
        vehicle_type: [
          'Passenger Cars',
          'Light-Duty Trucks',
          'Medium-Duty Vehicles',
          'Heavy-Duty Vehicles',
          'Motorcycles',
          'Light-Duty Cars',
          'Medium-Duty Truck',
          'Heavy-Duty Trucks',
          'Buses',
          'Ships and Boats',
          'Locomotives',
          'Aircraft',
          'Agricultural Equipment',
          'Construction/Mining Equipment',
          'Lawn and Garden Equipment',
          'Airport Equipment',
          'Industrial/Commercial Equipment',
          'Logging Equipment',
          'Railroad Equipment',
          'Recreational Equipment'
        ],
       
       
        unit_of_fuel_amount: [
          { type : "standard cubic foot (scf)", value : 'scf'},
          { type : "US gallon (gal)", value : 'gal'},
          { type : "barrel (bbl)", value : 'bbl'},
          { type : "liters (L)", value : 'l'},
          { type : "cubic meters (m3)", value : 'm3'},
         ],
        unit_of_fuel_amount_distance: ['nautical mile', 'mile', 'Km']
      },

      cfRefrigerentsTableData: [],
      cfRefrigerentsForm: false,
      isEditCfRefrigerentsForm: false,
      editDataCfRefrigerentsForm: {},
      editCfRefrigerentsDataIndex: {
        ind: null,
        key: null,
      },
      refrigerentsDropdownData: {
        refrigerant_used: [
          "Aviation Gasoline",
          "Biodiesel (100%)",
          "Carbon dioxide",
          "Carbon tetrachloride",
          "CFC-11",
          "CFC-12",
          "CFC-13",
          "CFC-113",
          "CFC-114",
          "CFC-115",
          "Ethanol (100%)",
          "Ferry",
          "Gasoline Agricultural Equipment",
          "Gasoline Heavy-duty Vehicles",
          "Gasoline Light-duty Trucks (Vans, Pickup Trucks, SUVs)",
          "Gasoline Motorcycles",
          "Gasoline Passenger Cars",
          "Gasoline Ships and Boats",
          "HCFC-123",
          "HCFC-124",
          "HCFC-141b",
          "HCFC-142b",
          "HCFC-21",
          "HCFC-225ca",
          "HCFC-225cb",
          "Halon-1211",
          "Halon-1301",
          "Halon-2402",
          "HFC-134a (R-134a)",
          "HFC-134a (R-135a)",
          "HFC-134a (R-136a)",
          "HFC-134a (R-137a)",
          "HFC-134a (R-138a)",
          "HFC-134a (R-139a)",
          "HFC-134a (R-140a)",
          "HFC-134a (R-141a)",
          "HFC-134a (R-142a)",
          "HFC-134a (R-143a)",
          "HFC-134a (R-144a)",
          "HFC-134a (R-145a)",
          "HFC-236cb (R-236cb)",
          "HFC-236ea (R-236ea)",
          "HFC-236fa (R-236fa)",
          "HFC-245ca (R-245ca)",
          "HFC-245fa (R-245fa)",
          "HFC-365mfc",
          "HFC-43-10mee (R-4310)",
          "Methane",
          "Methyl bromide",
          "Methyl chloroform",
          "Motor Gasoline",
          "Nitrogen trifluoride",
          "Nitrous oxide",
          "Other Gasoline Non-Road Vehicles",
          "PFC-116 (Perfluoroethane)",
          "PFC-14 (Perfluoromethane)",
          "PFC-218 (Perfluoropropane)",
          "PFC-3-1-10 (Perfluorobutane)",
          "PFC-4-1-12 (Perfluoropentane)",
          "PFC-5-1-14 (Perfluorohexane)",
          "PFC-9-1-18 (Perfluorodecalin)",
          "R-22 (HCFC-22)",
          "R-401A",
          "R-401B",
          "R-401C",
          "R-402A",
          "R-402B",
          "R-403A",
          "R-403B",
          "R-404A",
          "R-407A",
          "R-407B",
          "R-407C",
          "R-407D",
          "R-407E",
          "R-407F",
          "R-408A",
          "R-410A",
          "R-410B",
          "R-411A",
          "R-411B",
          "R-412A",
          "R-415A",
          "R-415B",
          "R-416A",
          "R-417A",
          "R-417B",
          "R-417C",
          "R-418A",
          "R-419A",
          "R-419B",
          "R-420A",
          "R-421A",
          "R-421B",
          "R-422A",
          "R-422B",
          "R-422C",
          "R-422D",
          "R-422E",
          "R-423A",
          "R-424A",
          "R-425A",
          "R-426A",
          "R-427A",
          "R-428A",
          "R-429A",
          "R-430A",
          "R-431A",
          "R-434A",
          "R-435A",
          "R-437A",
          "R-438A",
          "R-439A",
          "R-444A",
          "R-445A",
          "R-500",
          "R-503",
          "R-504",
          "R-507",
          "R-507A",
          "R-509",
          "R-509A",
          "R-512A",
          "Sulfur hexafluoride"
        ]
      },      
      newfuelDropDown : [
        {
        type: "Solids",  value : "Anthracite Coal"
      },
      {
        type: "Solids",  value : "Bituminous Coal"
      },  {
        type: "Solids",  value : "Sub-bituminous Coal"
      },  {
        type: "Solids",  value : "Lignite Coal"
      },  {
        type: "Solids",  value : "Mixed (Commercial Sector)"
      },  {
        type: "Solids",  value : "Mixed (Electric Power Sector)"
      }, {
        type: "Solids",  value : "Mixed (Electric Power Sector)"
      },
      {
        type: "Solids",  value : "Mixed (Industrial Coking)"
      },
      {
        type: "Solids",  value : "Mixed (Industrial Sector)"
      },{
        type: "Solids",  value : "Coal Coke"
      },{
        type: "Solids",  value : "Municipal Solid Waste"
      },
      {
        type: "Solids",  value : "Petroleum Coke (Solid)"
      },
      {
        type: "Solids",  value : "Plastics"
      },
      {
        type: "Solids",  value : "Tires"
      }, {
        type: "Solids",  value : "Agricultural Byproducts"
      },
      {
        type: "Solids",  value : "Peat"
      },
      {
        type: "Solids",  value : "Solid Byproducts"
      },{
        type: "Solids",  value : "Wood and Wood Residuals"
      },
      {
        type: "Gases",  value : "Natural Gas"
      },
      {
        type: "Gases",  value : "Propane Gas"
      },
      {
        type: "Gases",  value : "Landfill Gas"
      },
      {
        type: "Gases",  value : "Distillate Fuel Oil No. 2"
      },
      {
        type: "Gases",  value : "Residual Fuel Oil No. 6"
      },
      {
        type: "Gases",  value : "Kerosene"
      },
      {
        type: "Gases",  value : "Liquefied Petroleum Gases (LPG)"
      },
     
      {
        type: "Liquids",  value : "Biodiesel (100%)"
      },{
        type: "Liquids",  value : "Ethanol (100%)"
      },{
        type: "Liquids",  value : "Rendered Animal Fat"
      },
      {
        type: "Liquids",  value : "Vegetable Oil"
      },     
    ], 
    steamFuelTypes:[
      "Natural Gas",	
      "Distillate Fuel Oil No. 2",	
      "Residual Fuel Oil No. 6",	
      "Kerosene",	
      "Liquefied Petroleum Gases (LPG)",	
      "Anthracite Coal",	
      "Bituminous Coal",	
      "Sub-bituminous Coal",	
      "Lignite Coal",	
      "Mixed (Electric Power Sector)",	
      "Coal Coke",	
      "Wood and Wood Residuals",	
      "Landfill Gas"
    ],
    offsetsScops:[
      "Stationary Combustion",
      "Mobile Sources",
      "Refrigeration and AC",
      "Fire Suppression",
      "Purchased Gases",
      "Electricity",
      "Steam",
      "Transportation and Distribution",
      "Waste",
    ],
      fuelDropdownData: [
        "Anthracite Coal",
        "Bituminous Coal",
        "Sub-bituminous Coal",
        "Lignite Coal",
        "Mixed (Commercial Sector)",
        "Mixed (Electric Power Sector)",
        "Mixed (Industrial Coking)",
        "Mixed (Industrial Sector)",
        "Coal Coke",
        "Municipal Solid Waste",
        "Petroleum Coke (Solid)",
        "Plastics",
        "Tires",
        "Agricultural Byproducts",
        "Peat",
        "Solid Byproducts",
        "Wood and Wood Residuals",
        "Natural Gas",
        "Blast Furnace Gas",
        "Coke Oven Gas",
        "Fuel Gas",
        "Propane Gas",
        "Landfill Gas",
        "Other Biomass Gases",
        "Asphalt and Road Oil",
        "Aviation Gasoline",
        "Butane",
        "Butylene",
        "Crude Oil",
        "Distillate Fuel Oil No. 1",
        "Distillate Fuel Oil No. 2",
        "Distillate Fuel Oil No. 4",
        "Ethane",
        "Ethylene",
        "Heavy Gas Oils",
        "Isobutane",
        "Isobutylene",
        "Kerosene",
        "Kerosene-Type Jet Fuel",
        "Liquefied Petroleum Gases (LPG)",
        "Lubricants",
        "Motor Gasoline",
        "Naphtha (<401 deg F)",
        "Natural Gasoline",
        "Other Oil (>401 deg F)",
        "Pentanes Plus",
        "Petrochemical Feedstocks",
        "Petroleum Coke",
        "Propane",
        "Propylene",
        "Residual Fuel Oil No. 5",
        "Residual Fuel Oil No. 6",
        "Special Naphtha",
        "Unfinished Oils",
        "Used Oil",
        "Biodiesel (100%)",
        "Ethanol (100%)",
        "Rendered Animal Fat",
        "Vegetable Oil"        
      ],
      fuelDropdownDataEfExist: ["Example S1"],
      unitsDropdownData: [
        'Btu',
        'GJ',
        'kWh',
        'mmBtu',
        'MJ',
        'MWh',
        'therm'],
      stationaryunitsDropdownData: [
        {type: "Solids", units : [
          {text : "pounds (lb)", value : 'p'},
          {text : "kilogram (kg)", value : 'kg'},
          {text : "gram (g)", value : 'g'},
          {text : "metric ton", value :'metric_ton'},
          {text : "short ton", value : 'short_ton'}
       ]},
        {type: "Gases", units : [
          {text : "standard cubic foot (scf)", value : 'scf'},
          {text : "US gallon (gal)", value : 'gal'},
          {text : "barrel (bbl)", value : 'bbl'},
          {text : "liters (L)", value : 'l'},
          {text : "cubic meters (m3)", value : 'm3'}

         ]},
          {type: "Liquids", units : [
            {text : "standard cubic foot (scf)", value : 'scf'},
            {text : "US gallon (gal)", value : 'gal'},
            {text : "barrel (bbl)", value : 'bbl'},
            {text : "liters (L)", value : 'l'},
            {text : "cubic meters (m3)", value : 'm3'} 
        ]}
        
      ],
      facilityIdDropdownData: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
        "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
        "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
        "41", "42", "43", "44", "45", "46", "47", "48", "49", "50"],
      yearsDropdownData: ["2019","2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040", "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049", "2050"],
      countriesDropdownData: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Côte d\'Ivoire',
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo (Congo-Brazzaville)',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czechia (Czech Republic)',
        'Democratic Republic of the Congo',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini (fmr. "Swaziland")',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Holy See',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar (formerly Burma)',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'North Korea',
        'North Macedonia',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine State',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Korea',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Sweden',
        'Switzerland',
        'Syria',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States of America',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbawe',
      ],
      gasTypeFireSuppressionDropdown:[
          "Carbon dioxide",
          "Methane",
          "Nitrous oxide",
          "HFC-23",
          "HFC-32",
          "HFC-41",
          "HFC-125",
          "HFC-134", 
          "HFC-134a", 
          "HFC-143",
          "HFC-143a", 
          "HFC-152",
          "HFC-152a", 
          "HFC-161",
          "HFC-227ea", 
          "HFC-236cb", 
          "HFC-236ea",
          "HFC-236fa", 
          "HFC-245ca", 
          "HFC-245fa", 
          "HFC-365mfc", 
          "HFC-43-10mee", 
          "Sulfur hexafluoride", 
          "Nitrogen trifluoride", 
          "PFC-14",
          "PFC-116", 
          "PFC-218", 
          "PFC-318", 
          "PFC-31-10", 
          "PFC-41-12", 
          "PFC-51-14", 
          "PFC-91-18",
      ],

      wasteMaterialDropdwon:[
        "Aluminum Cans",
        "Aluminum Ingot",
        "Steel Cans",
        "Copper Wire",
        "Glass",
        "LDPE",
        "PET",
        "LLDPE",
        "PP",
        "PS",
        "PVC",
        "PLA",
        "Corrugated Containers",
        "Magazines/Third-class mail",
        "Newspaper",
        "Office Paper",
        "Phonebooks",
        "Textbooks",
        "Dimensional Lumber",
        "Medium-density Fiberboard",
        "Food Waste (non-meat)",
        "Food Waste (meat only)",
        "Beef",
        "Poultry",
        "Grains",
        "Bread",
        "Fruits and Vegetables",
        "Dairy Products",
        "Yard Trimmings",
        "Grass",
        "Leaves",
        "Branches",
        "Mixed Paper (general)",
        "Mixed Paper (primarily residential)",
        "Mixed Paper (primarily from offices)",
        "Mixed Metals",
        "Mixed Plastics",
        "Mixed Recyclables",
        "Food Waste",
        "Mixed Organics",
        "Mixed MSW",
        "Carpet",
        "Desktop CPUs",
        "Portable Electronic Devices",
        "Flat-panel Displays",
        "CRT Displays",
        "Electronic Peripherals",
        "Hard-copy Devices",
        "Mixed Electronics",
        "Clay Bricks",
        "Concrete",
        "Fly Ash",
        "Tires",
        "Asphalt Concrete",
        "Asphalt Shingles",
        "Drywall",
        "Fiberglass Insulation",
        "Structural Steel",
        "Vinyl Flooring",
        "Wood Flooring",
      ],

      wasteDisposalMethodDropdown:[
        'Recycled',
        'Landfilled',
        'Combusted'
      ],
      units_of_weightDropdown:[
        "g",
        "kg",
        "lb",
        "metric_ton",
        "short_ton",
      ],
      offsetsScopeDropdownData:[
        'Scope 2 - Location-Based',
        'Scope 2 - Market-Based',
        'Scope 3 - Business Travel',
        'Scope 3 - Employee Commuting',
        'Scope 3 - Upstream Transportation and Distribution'
      ],
      purchaseCommodityDropdownData:[
        'Scope 2 - Location-Based',
        'Scope 2 - Market-Based',
        'Scope 3 - Business Travel',
        'Scope 3 - Employee Commuting',
      ],
      saveAsDraftData: [],
      total_pages: 1,
      current_page: 1,

      scDeleteId: [],
      mcDeleteId: [],
      rfDeleteID: [],
      peDeleteId: [],
      tsDeleteId: [],
      fsDeleteId: [],
      steamDeleteId:[],
      offsetsDeleteID:[],
      wasteDeletedID:[],

      isDeleteModelOpen: false,
      deleteModelIndex: null,
      isDraftModelOpen: false,
      isSaveModelOpen: false,

      SaveDraftObserver: false,

      isViewForm: false,

      isReadable: false,

      submitDataLoader: false,
      fuelListStationary: [],
      fuelSourceListMobile: [],
      fuelSourceUnits :[],
      vehicleTypeListMobile: [],
      fuelTypeListMobile:[],
      activityTypeList:[],
      vehicleTypeListTransport:[],
      filterPurchasedEmissionFactor: [],
      unitsOfMeasurement: [
        { activity_type: 'Example S3', value: 'km' },
        { activity_type: 'Example S3', value: 'mile' },
        { activity_type: 'Example S3', value: 'nautical mile' },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({ loading: true });
    this.path = this.context.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    const authTokenData = await getStorageData('authToken')
    this.token = authTokenData;
    const tabHeaderData = this.getTableHeaderDataByType('s1_stationaryCombustion')
    this.setState({ cfNavActivate: 's1_stationaryCombustion', tableHeader: tabHeaderData, CfStationaryCombustionTableData: [] }, () => {
      this.getstationaryCombustionData();
    })

    const abilityType = this.props?.navigation?.history?.location?.state?.state?.ability_type;
    const ability_type = await getStorageData('ability_type')
    if(abilityType == "read" || ability_type == "read"){
      this.setState({isReadable:true})
    }
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage)); this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
      runEngine.debugLog("API Message Recived", message);
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.error){
          this.parseApiErrorResponse(responseJson.error);
          this.parseApiCatchErrorResponse(responseJson.error);
          toast.error(responseJson.error);
          this.setState({ loading: false,submitDataLoader: false });
        }
        else if (responseJson) {
          this.handleApiResponse(apiRequestCallId, responseJson)
          this.setState({ loading: false });
        }
      }

    // Customizable Area End
  }

  // Customizable Area Start
  
  visibleTabs = 5;
  handleLeftClick = () => {
    this.setState((prevState) => ({
      startIndex: Math.max(0, prevState.startIndex - 1),
    }));
  };
  handleRightClick = () => {
    this.setState((prevState) => ({
      startIndex: Math.min(
        this.state.typesOfScope.length - this.visibleTabs,
        prevState.startIndex + 1
      ),
    }));
  };

  
  handlePageChange = (event: any, value: any) => {
    switch (this.state.cfNavActivate) {
      case "s1_stationaryCombustion":
        this.setState({ current_page: value }, () => this.getstationaryCombustionData(value));
        break;

      case "s1_mobileCombustion":
        this.setState({ current_page: value }, () => this.getmobileCombustionData(value));
        break;

      case "s1_refrigerants":
        this.setState({ current_page: value }, () => this.getrefrigerentsData(value));
        break;

      case "s2_purchasedElectricity":
        this.setState({ current_page: value }, () => this.getPurchasedElectricityData(value));
        break;

      case "s3_transportation":
        this.setState({ current_page: value }, () => this.getTransportationData(value));
        break;
      case "s1_fireSuppression":
          this.setState({ current_page: value }, () => this.getFireSuppressionData(value));
          break;  
      case "s2_steam":
          this.setState({ current_page: value }, () => this.getSteamData(value));
          break; 
       case "s3_waste":
          this.setState({ current_page: value }, () => this.getWasteData(value));
          break;
      case "offsets":
            this.setState({ current_page: value }, () => this.getOffsetsData(value));
            break;  
       case "purchase_commodity":
           this.setState({ current_page: value }, () => this.getPurchaseCommodityData(value));
            break;           
    }
  }

  stationaryCombustionFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    start_date: yup.string().required('start_date is Mandatory'),
    end_date: yup.string().required('end_date is Mandatory'),
    address: yup.string().required('Location is Mandatory'),
    custom_emissions_factors: yup.string().required('Custom Emissions Factors is Mandatory'),
    amount_of_fuel: yup.number().required('Amount of fuel is Mandatory').typeError('Amount of fuel must be a number').max(9999999999, 'Amount of fuel field cannot exceed 10 digits'),
    fuel_name: yup.string().required('Fuel is Mandatory'),
    unit_name: yup.string().required('Units (e.g.kg or kWh) is Mandatory'),
  })

  mobileCombustionFormSchema = yup.object({ 
    description: yup.string().required('Description is Mandatory').max(550, 'Description must be at most 550 characters'),
    facility_id: yup.string().required('Facility Id is Mandatory'),
    activity_type: yup.string().required('Activity Type is Mandatory'),
    activity_amount: yup.number().required('Activity Amount is Mandatory').typeError('Activity Amount must be a number').max(9999999999, 'Activity Amount field cannot exceed 10 digits'),
    fuel_source: yup.string().required('Fuel Source is Mandatory'),
    address: yup.string().required('Location is Mandatory'),
    travals: yup.number().required('Travals miles is Mandatory'),
    vehicle_type: yup.string().required('Vehicle Type is Mandatory'),
    unit_of_fuel_amount: yup.string().required('Unit of Fuel Amount is Mandatory'),
  })

  refrigerentsFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    refrigerant_purchased_from_producers: yup.number().required('Refrigerant purchased from producers/ distributors is Mandatory').typeError('Refrigerant purchased from producers/ distributors must be a number').max(9999999999, 'Refrigerant purchased from producers/ distributors field cannot exceed 10 digits'),
    refrigerant_returned_by_equipment_users: yup.number().required('Refrigerant returned by equipment users is Mandatory').typeError('Refrigerant returned by equipment users must be a number').max(9999999999, 'Refrigerant returned by equipment users field cannot exceed 10 digits'),
    refrigerant_off_site_recycling_purchases: yup.number().required('Refrigerant returned after off-site recycling or reclamation is Mandatory').typeError('Refrigerant returned after off-site recycling or reclamation must be a number').max(9999999999, 'Refrigerant returned after off-site recycling or reclamation field cannot exceed 10 digits'),
    refrigerant_charged_into_equipment: yup.number().required('Refrigerant charged into equipment is Mandatory').typeError('Refrigerant charged into equipment must be a number').max(9999999999, 'Refrigerant charged into equipment field cannot exceed 10 digits'),
    refrigerant_delivered_to_equipment_users_containers: yup.number().required('Refrigerant delivered to equipment users in containers is Mandatory').typeError('Refrigerant delivered to equipment users in containers must be a number').max(9999999999, 'Refrigerant delivered to equipment users in containers field cannot exceed 10 digits'),
    refrigerant_returned_to_producers: yup.number().required('Refrigerant returned to refrigerant producers is Mandatory').typeError('Refrigerant returned to refrigerant producers must be a number').max(9999999999, 'Refrigerant returned to refrigerant producers field cannot exceed 10 digits'),
    refrigerant_off_site_recycling_sales: yup.number().required('Refrigerant sent off-site for recycling or reclamation is Mandatory').typeError('Refrigerant sent off-site for recycling or reclamation must be a number').max(9999999999, 'Refrigerant sent off-site for recycling or reclamation field cannot exceed 10 digits'),
    refrigerant_sent_off_site_destruction: yup.number().required('Refrigerant sent off-site for destruction is Mandatory').typeError('Refrigerant sent off-site for destruction must be a number').max(9999999999, 'Refrigerant sent off-site for destruction field cannot exceed 10 digits'),
    capacity_of_partially_charged_equipment: yup.number().required('Nameplate Capacity of Partially Charged Equipment is Mandatory').typeError('Nameplate Capacity of Partially Charged Equipment must be a number').max(9999999999, 'Nameplate Capacity of Partially Charged Equipment field cannot exceed 10 digits'),
    pressure_of_partial_charge: yup.number().required('Density or Pressure of partial charge is Mandatory').typeError('Density or Pressure of partial charge must be a number').max(9999999999, 'Density or Pressure of partial charge  field cannot exceed 10 digits'),
    pressure_of_full_charge: yup.number().required('Density or Pressure of full charge is Mandatory').typeError('Density or Pressure of full charge must be a number').max(9999999999, 'Density or Pressure of full charge field cannot exceed 10 digits'),
    type_of_air_conditioning_and_refrigeration_equipment: yup.number().required('Type of Air Conditioning and Refrigeration Equipment is Mandatory').typeError('Type of Air Conditioning and Refrigeration Equipment must be a number').max(9999999999, 'Type of Air Conditioning and Refrigeration Equipment field cannot exceed 10 digits'),
    refrigerant_inventory_beginning_year: yup.number().required('Refrigerant inventory in storage, not equipment at the beginning of the year is Mandatory').typeError('Refrigerant inventory in storage, not equipment at the beginning of the year must be a number').max(9999999999, 'Refrigerant inventory in storage, not equipment at the beginning of the year field cannot exceed 10 digits'),
    refrigerant_inventory_end_year: yup.number().required('Refrigerant inventory in storage, not equipment at the end of the year is Mandatory').typeError('Refrigerant inventory in storage, not equipment at the end of the year must be a number').max(9999999999, 'Refrigerant inventory in storage, not equipment at the end of the year field cannot exceed 10 digits'),
    refrigerant_used:yup.string().required('Refrigerant Used is Mandatory'),
  })

  purchasedElectricityFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    amount_of_electricity_consumption: yup.number().required('Amount Of Electricity Consumption is Mandatory').typeError('Amount Of Electricity Consumption must be a number').max(9999999999, 'Amount Of Electricity Consumption field cannot exceed 10 digits'),
    calculation_approach: yup.string().required('Calculation Approach is Mandatory'),
    type_of_emission_factor: yup.string().required('Types Of Emission Factor is Mandatory'),
    custom_emission_factor: yup.string().required('Custom Emission Factor is Mandatory'),
    units: yup.string().required('Units is Mandatory'),
  })

  transportationFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    description: yup.string().required('Description is Mandatory').max(550, 'Description must be at most 550 characters'),
    category: yup.string().required('Category is Mandatory'),
    amount_of_activity_type: yup.number().when('category', {
      is: (value) => value === '' || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
      then: yup.number().required('Amount of Activity Type is Mandatory').typeError('Amount of Activity Type must be a number').max(9999999999, 'Amount of Activity Type field cannot exceed 10 digits'),
      otherwise: yup.number()
    }),
    emission_factor_dataset: yup.string().required('Emission Factor Dataset is Mandatory'),
    mode_of_transport: yup.string().when('category', {
    is: (value) => value === "" || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
    then: yup.string().required('Mode Of Transport is Mandatory'),
    otherwise: yup.string()
  }),
  activity_type: yup.string().when('category', {
    is: (value) => value === "" || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
    then: yup.string().required('Activity Type is Mandatory'),
    otherwise: yup.string()
  }),
  vehicle_type: yup.string().when('category', {
    is: (value) => value === "" || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
    then: yup.string().required('Vehicle Type is Mandatory'),
    otherwise: yup.string()
  }),
    units_of_measurement:yup.string().required('Units Of Measurement is Mandatory'),

  })
wasteFormSchema = yup.object({
  facility_id: yup.string().required('Facility Id is Mandatory'),
  start_date: yup.string().required('start_date is Mandatory'),
  end_date: yup.string().required('end_date is Mandatory'),
  waste_material: yup.string().required('waste material is mandatory'),
  disposal_method: yup.string().required('disposal method is mandatory'),
  weight: yup.number().required('weight  is mandatory'),
  unit: yup.string().required('Unit (e.g., kg or kWh) is mandatory'),
})
offsetsFormSchema = yup.object({
  facility_id: yup.string().required('Facility Id is Mandatory'),
  start_date: yup.string().required('start_date is Mandatory'),
  end_date: yup.string().required('end_date is Mandatory'),
  offset_scope: yup.string().required('offset scope is mandatory'),
  offset_purchased: yup.number().required('offset purchased is mandatory'),
})

purchaseCommodityFormSchema = yup.object({
  facility_id: yup.string().required('Facility Id is Mandatory'),
  start_date: yup.string().required('start_date is Mandatory'),
  end_date: yup.string().required('end_date is Mandatory'),
  supplier_name: yup.string().required('supplier name or purchasing category is mandatory'), 
  annual_speed: yup.number().required('annual speed is mandatory'),
  industry: yup.string().required('industry is mandatory'),
  industry_subcategory: yup.string().required('industry subcategory is mandatory'),
  commodity: yup.string().required('commodity is mandatory'),
  recommended_scope_3_category: yup.string().required('recomanded scope 3 category is mandatory'),

})


FireSuppressionFormSchema = yup.object({
  facility_id: yup.string().required('Facility Id is Mandatory'),
  start_date: yup.string().required('start_date is Mandatory'),
  end_date: yup.string().required('end_date is Mandatory'),
  address: yup.string(),
  gas: yup.string().required('gas type is mandatory'),
  new_unit_charge: yup.number().required('New Units Charge is mandatory'),
  new_unit_capacity: yup.number().required('New Units Capacity is mandatory'),
  existing_unit_recharge: yup.number().required('Existing Units Recharge is mandatory'),
  disposed_unit_capacity: yup.number().required('Disposed Units Capacity is mandatory'),
  disposed_unit_charge: yup.number().required('Disposed Units Charge is mandatory'),

})
 
SteamFormSchema = yup.object({
  facility_id: yup.string().required('Facility Id is Mandatory'),
  start_date: yup.string().required('start_date is Mandatory'),
  end_date: yup.string().required('end_date is Mandatory'),
  source_id:yup.number().required('source_id is Mandatory'),
  description: yup.string().required('Description is Mandatory').max(550, 'Description must be at most 550 characters'),
  source_area: yup.number().required('source area is mandatory'), 
  fuel_type: yup.string().required('fuel type is mandatory'),
  boiler_efficiency: yup.number().required('boiler efficiency is mandatory'),
  steam_purchased: yup.number().required('steam purchased is mandatory'),
  co2_factor: yup.number().required('co2_factor is mandatory'),
  ch4_factor: yup.number().required('ch4_factor is mandatory'),
  n2o_factor: yup.number().required('n2o_facto is mandatory'),
 
})

BounadrayQuestionsFormSchema = yup.object({
  facility_id: yup.string().required('Facility Id is Mandatory'),
  start_date: yup.string().required('start_date is Mandatory'),
  end_date: yup.string().required('end_date is Mandatory'),
  stationary_combustion: yup.string().required('stationay comustion is mandatory'), 
  mobile_sources: yup.string().required('mobile sources is mandatory'),
  fire_suppression: yup.string().required('fire suppression is mandatory'),
  purchased_gases: yup.string().required('purchased gases is mandatory'),
  electricity: yup.string().required('electricity is mandatory'),
  steam: yup.string().required('steam is mandatory'),
  markbased_emissionf: yup.string().required('markebased emission factor is mandatory'),
  business_travel: yup.string().required('business is mandatory'),
  employe_commuting: yup.string().required('employe commuting is mandatory'),
  upstrem_tf_distribution: yup.string().required('Upstream Transportation and Distribution is mandatory'),
  waste_gemration_operations: yup.string().required('Waste Generated in Operation is mandatory'),
  offsets: yup.string().required('Offsets is mandatory'), 

})
  CfNavActivation = (key: string) => {
    const tabHeaderData = this.getTableHeaderDataByType(key);

    if(this.state.SaveDraftObserver){
      this.handleDraftData(this.state.cfNavActivate);
    }

    this.setState({ cfNavActivate: key, tableHeader: tabHeaderData, loading: true, pdfUrl: ''})
    switch(key) {
      case "s1_stationaryCombustion":
          this.getstationaryCombustionData()
        break;
      case "s1_mobileCombustion":
          this.getmobileCombustionData()
        break;
      case "s1_refrigerants":
          this.getrefrigerentsData()
        break;
      case "s2_purchasedElectricity":
          this.getPurchasedElectricityData()
        break;
      case "s3_transportation":
          this.getTransportationData()
        break;
        case "s1_fireSuppression":
          this.getFireSuppressionData()
        break;
        case "s2_steam":
          this.getSteamData()
        break;
        case "s3_waste": 
          this.getWasteData()
        break;
        case "offsets":
          this.getOffsetsData()
        break;
        case "purchase_commodity":
          this.getPurchaseCommodityData()
        break;
        case "boundary_equations":
          this.getPurchaseCommodityData()
        break;
    }
  }

  CfHandleFormModel = (activeTab: string) => {
    switch (activeTab) {
      case "s1_stationaryCombustion":
        this.setState({ cfStationaryCombustionForm: !this.state.cfStationaryCombustionForm, scopeFormTitle: 'S1-STATIONARY COMBUSTION'  })
        break;

      case "s1_mobileCombustion":
        this.setState({cfMobileCombustionForm: !this.state.cfMobileCombustionForm,scopeFormTitle:'S1-Mobile Combustion' })
        break;

      case "s1_refrigerants":
        this.setState({cfRefrigerentsForm: !this.state.cfRefrigerentsForm,scopeFormTitle:'S1-Refrigerants' })
        break;

      case "s2_purchasedElectricity":
        this.setState({cfPurchasedElectricityForm: !this.state.cfPurchasedElectricityForm,scopeFormTitle:'S2-Purchased Electricity' })
        break;

      case "s3_transportation":
        this.setState({cfTransportationForm: !this.state.cfTransportationForm,scopeFormTitle:'S3-Transportation' })
        break;
      case "s1_fireSuppression":
          this.setState({cfFireSuppresionForm: !this.state.cfFireSuppresionForm,scopeFormTitle:'S1-FIRE SUPPRESSION' })
          break;
      case "s2_steam":
          this.setState({cfSteamForm: !this.state.cfSteamForm,scopeFormTitle:'S2-STEAM' })
          break;
      case "s3_waste":
          this.setState({cfWasteForm: !this.state.cfWasteForm,scopeFormTitle:'S3-WASTE' })
          break;
     case "offsets":
          this.setState({cfOffsetsForm: !this.state.cfOffsetsForm,scopeFormTitle:'Offsets' })
           break;
      case "purchase_commodity":
         this.setState({cfPurchaseCommodityForm: !this.state.cfPurchaseCommodityForm,scopeFormTitle:'Purchase Commodity'})
         break;
     case "boundary_equations":
          this.setState({cfBoundaryQuestionsForm: !this.state.cfBoundaryQuestionsForm,scopeFormTitle:'BOUNDARY QUESTIONS'})
          break;   
    }
  }

  handleSubmitData = (activeTab: string) => {
    this.setState({ submitDataLoader: true });

    switch (activeTab) {
      case "s1_stationaryCombustion":
        this.submitDataScCallID = sendAPIRequest(configJSON.saveScTable, {
          method: 'POST',
          body: {
            stationary_combustions: this.state.CfStationaryCombustionTableData,
            deleted_ids: this.state.scDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s1_mobileCombustion":
        this.submitDataMcCallID = sendAPIRequest(configJSON.saveMcTable, {
          method: 'POST',
          body: {
            mobile_combustions: this.state.cfMobileCombustionTableData,
            deleted_ids: this.state.mcDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s1_refrigerants":
        this.submitDataRfCallID = sendAPIRequest(configJSON.saveRfTable, {
          method: 'POST',
          body: {
            refrigerants: this.state.cfRefrigerentsTableData,
            deleted_ids: this.state.rfDeleteID
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s2_purchasedElectricity":
        this.submitDataPeCallID = sendAPIRequest(configJSON.savePeTable, {
          method: 'POST',
          body: {
            ghgs: this.state.cfPurchasedElectricityTableData,
            deleted_ids: this.state.peDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s3_transportation":
        this.submitDataTsCallID = sendAPIRequest(configJSON.saveTsTable, {
          method: 'POST',
          body: {
            transportations: this.state.cfFireSuppresionTableData,
            deleted_ids: this.state.fsDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s1_fireSuppression":
        this.submitDataFsCallID = sendAPIRequest(configJSON.saveFsTable, {
          method: 'POST',
          body: {
            fire_suppressions: this.state.cfFireSuppresionTableData,
            deleted_ids: this.state.fsDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s2_steam":
        this.submitDataStmCallID = sendAPIRequest(configJSON.saveSteamTable, {
          method: 'POST',
          body: {
            steam_suppressions: this.state.cfSteamTableData,
            deleted_ids: this.state.steamDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "offsets":
        this.submitDataOffsetsCallID = sendAPIRequest(configJSON.saveOffsetsTable, {
          method: 'POST',
          body: {
            offsets_combustions: this.state.cfOffsetsTableData,
            deleted_ids: this.state.offsetsDeleteID
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s3_waste":
        this.submitDataWasteCallID = sendAPIRequest(configJSON.saveWasteTable, {
          method: 'POST',
          body: {
            waste_combustions: this.state.cfWasteTableData,
            deleted_ids: this.state.wasteDeletedID
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
    }

  }

  handleDraftData = async (activeTab:string) => {
    this.setState({loading: true});
    switch(activeTab) {
      case "s1_stationaryCombustion":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.CfStationaryCombustionTableData}]});
        this.draftDataScCallID = sendAPIRequest(configJSON.draftScTable, {
          method: 'POST',
          body: {
            stationary_combustions: this.state.CfStationaryCombustionTableData,
            deleted_ids: this.state.scDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s1_mobileCombustion":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfMobileCombustionTableData}]});
        this.draftDataMcCallID = sendAPIRequest(configJSON.draftMcTable, {
          method: 'POST',
          body: {
            mobile_combustions: this.state.cfMobileCombustionTableData,
            deleted_ids: this.state.mcDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s1_refrigerants":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfRefrigerentsTableData}]});
        this.draftDataRfCallID = sendAPIRequest(configJSON.draftRfTable, {
          method: 'POST',
          body: {
            refrigerants: this.state.cfRefrigerentsTableData,
            deleted_ids: this.state.rfDeleteID
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s2_purchasedElectricity":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfPurchasedElectricityTableData}]});
        this.draftDataPeCallID = sendAPIRequest(configJSON.draftPeTable, {
          method: 'POST',
          body: {
            ghgs: this.state.cfPurchasedElectricityTableData,
            deleted_ids: this.state.peDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s3_transportation":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfTransportationTableData}]});
        this.draftDataTransportationsCallID = sendAPIRequest(configJSON.draftTransportationTable, {
          method: 'POST',
          body: {
            transportations: this.state.cfTransportationTableData,
            deleted_ids: this.state.tsDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
        case "s1_fireSuppression":
          this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfFireSuppresionTableData}]});
          this.draftDataSFsCallID = sendAPIRequest(configJSON.draftFsTable, {
            method: 'POST',
            body: {
              fire_suppressions: this.state.cfFireSuppresionTableData,
              deleted_ids: this.state.fsDeleteId
            },
            headers: {
              "Content-Type": "application/json",
              token: this.token
            }
          })
          break;
          case "s2_steam":
          this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfSteamTableData}]});
          this.draftDataStmCallID = sendAPIRequest(configJSON.draftSteamTable, {
            method: 'POST',
            body: {
              steam_suppressions: this.state.cfSteamTableData,
              deleted_ids: this.state.steamDeleteId
            },
            headers: {
              "Content-Type": "application/json",
              token: this.token
            }
          })
          break;
          case "offsets":
          this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfOffsetsTableData}]});
          this.draftDataOffsetsCallID = sendAPIRequest(configJSON.draftOffsetsTable, {
            method: 'POST',
            body: {
              offsets_combustions: this.state.cfOffsetsTableData,
              deleted_ids: this.state.offsetsDeleteID
            },
            headers: {
              "Content-Type": "application/json",
              token: this.token
            }
          })
          break;

          case "s3_waste":
            this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfWasteTableData}]});
            this.draftDataWasteCallID = sendAPIRequest(configJSON.draftWasteTable, {
              method: 'POST',
              body: {
                waste_combustions: this.state.cfWasteTableData,
                deleted_ids: this.state.wasteDeletedID
              },
              headers: {
                "Content-Type": "application/json",
                token: this.token
              }
            })
            break;
      }
      
    this.setState({SaveDraftObserver: false});
  }

  getInitialValuesSc(isEdit:boolean, editData:any) {
    return isEdit
      ? {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          amount_of_fuel: editData.amount_of_fuel,
          custom_emissions_factors: editData.custom_emissions_factors,
          address: editData.address,
          fuel_name: editData.fuel_name,
          unit_name: editData.unit_name
        }
      : {
          facility_id: "",
          amount_of_fuel: "",
          custom_emissions_factors: "",
          address: "",
          fuel_name: "",
          unit_name: "",
          start_date:new Date(),
          end_date:new Date()
        };
  }

  getInitialValuesMc(isEdit:boolean, editData:any) {
    return isEdit
      ? {
        facility_id: editData.facility_id,
        start_date: editData.start_date,
        end_date: editData.end_date,     
        description: editData.description,
        address: editData.address,
        activity_type: "Distance Activity",
        fuel_source: editData.fuel_source,
        travals: editData.travals,
        vehicle_type: editData.vehicle_type,
        activity_amount:editData.activity_amount,
        unit_of_fuel_amount: editData.unit_of_fuel_amount,
        }
      : {
        facility_id: '',           
        description: '',
        address: '',
        activity_type: "Distance Activity",
        fuel_source:'',
        travals: '',
        vehicle_type: '',
        activity_amount:'',
        unit_of_fuel_amount: '',
        start_date:new Date(),
        end_date:new Date()
        };
  }
  getInitialValuesRf(isEdit:boolean, editData:any) {
    if (isEdit) {
      return {
        start_date: editData.start_date,
        end_date: editData.end_date,
        facility_id: editData.facility_id,
        location: editData.location,
        type_of_air_conditioning_and_refrigeration_equipment: editData.type_of_air_conditioning_and_refrigeration_equipment,
        refrigerant_used: editData.refrigerant_used,
        refrigerant_inventory_beginning_year: editData.refrigerant_inventory_beginning_year,
        refrigerant_inventory_end_year: editData.refrigerant_inventory_end_year,
        refrigerant_purchased_from_producers: editData.refrigerant_purchased_from_producers,
        refrigerant_returned_by_equipment_users: editData.refrigerant_returned_by_equipment_users,
        refrigerant_off_site_recycling_purchases: editData.refrigerant_off_site_recycling_purchases,
        refrigerant_charged_into_equipment: editData.refrigerant_charged_into_equipment,
        refrigerant_delivered_to_equipment_users_containers: editData.refrigerant_delivered_to_equipment_users_containers,
        refrigerant_returned_to_producers: editData.refrigerant_returned_to_producers,
        refrigerant_off_site_recycling_sales: editData.refrigerant_off_site_recycling_sales,
        refrigerant_sent_off_site_destruction: editData.refrigerant_sent_off_site_destruction,
        capacity_of_partially_charged_equipment: editData.capacity_of_partially_charged_equipment,
        pressure_of_partial_charge: editData.pressure_of_partial_charge,
        pressure_of_full_charge: editData.pressure_of_full_charge
      };
    } else {
      return {
        start_date: new Date(),
        end_date: new Date(),
        facility_id: "",
        location: "",
        type_of_air_conditioning_and_refrigeration_equipment: "",
        refrigerant_used: "",
        refrigerant_inventory_beginning_year: "",
        refrigerant_inventory_end_year: "",
        refrigerant_purchased_from_producers: "",
        refrigerant_returned_by_equipment_users: "",
        refrigerant_off_site_recycling_purchases: "",
        refrigerant_charged_into_equipment: "",
        refrigerant_delivered_to_equipment_users_containers: "",
        refrigerant_returned_to_producers: "",
        refrigerant_off_site_recycling_sales: "",
        refrigerant_sent_off_site_destruction: "",
        capacity_of_partially_charged_equipment: "",
        pressure_of_partial_charge: "",
        pressure_of_full_charge: ""
      };
    }
  }  

  getInitialValuesTS(isEdit:boolean) {
    if (isEdit) {
      return {
        facility_id:  this.state.editDataCfTransportationForm.facility_id,
        start_date: this.state.editDataCfTransportationForm.start_date,
        end_date: this.state.editDataCfTransportationForm.end_date ,
        description: this.state.editDataCfTransportationForm.description,
        category:this.state.editDataCfTransportationForm.category,
        location: this.state.editDataCfTransportationForm.location ,
        emission_factor_dataset: this.state.editDataCfTransportationForm.emission_factor_dataset,
        mode_of_transport: this.state.editDataCfTransportationForm.mode_of_transport ,
        activity_type: this.state.editDataCfTransportationForm.activity_type ,
        vehicle_type:this.state.editDataCfMobileCombustionForm.vehicle_type,
        amount_of_activity_type: this.state.editDataCfMobileCombustionForm.amount_of_activity_type,
        emission_calculation_methodology: this.state.editDataCfMobileCombustionForm.emission_calculation_methodology,
          number_of_units: this.state.editDataCfMobileCombustionForm.number_of_units,
        units_of_measurement: this.state.editDataCfTransportationForm.units_of_measurement,
        co2_emissions: this.state.editDataCfTransportationForm.co2_emissions,
        percentage_emissions: this.state.editDataCfTransportationForm.percentage_emissions,
        co2_tonnes: this.state.editDataCfTransportationForm.co2_tonnes,
        ch4_tonnes: this.state.editDataCfTransportationForm.ch4_tonnes,
        n2o_tonnes: this.state.editDataCfTransportationForm.n2o_tonnes,
        co2e_tonnes: this.state.editDataCfTransportationForm.co2e_tonnes,
        biofuel_co2_tonnes: this.state.editDataCfTransportationForm.biofuel_co2_tonnes,
        ef_kg: this.state.editDataCfTransportationForm.ef_kg,
        total_emission: this.state.editDataCfTransportationForm.total_emission,
      };
    } else {
      return {
        facility_id:  '',
        start_date: new Date(),
        end_date: new Date(),
        description: '',
        category:'',
        location: '' ,
        emission_factor_dataset: '',
        mode_of_transport: '',
        activity_type: '',
        vehicle_type:'',
        amount_of_activity_type: '',
        emission_calculation_methodology: '',
          number_of_units: '',
        units_of_measurement: '',
        co2_emissions: '',
        percentage_emissions: '',
        co2_tonnes: '',
        ch4_tonnes: '',
        n2o_tonnes:'',
        co2e_tonnes: '',
        biofuel_co2_tonnes: '',
        ef_kg: '',
        total_emission: '',
      };
    }
  }  

getInitialValuesForWaste(isEdit: boolean, editData:any) {
  if (isEdit) {
      return {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          address: editData.address,
          waste_material: editData.waste_material,
          disposal_method: editData.disposal_method,
          weight: editData.weight,
          unit: editData.unit,
      };
  } else {
      return {
          facility_id: '',
          start_date: new Date(),
          end_date: new Date(),
          address: '',
          description: '',
          waste_material: '',
          disposal_method: '',
          weight: '',
          unit: '',
      };
  }
}

getInitialValuesForOffsets(isEdit: boolean, editData:any) {
  if (isEdit) {
      return {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          address: editData.address,
          offset_scope: editData.offset_scope,
          offset_purchased: editData.offset_purchased, 
      };
  } else {
      return {
          facility_id: '',
          start_date: new Date(),
          end_date: new Date(),
          address: '',
          offset_scope: '',
          offset_purchased: '',
      };
  }
}

getInitialValuesForPurchaseCommodity(isEdit: boolean, editData:any) {
  if (isEdit) {
      return {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          location: editData.location,
          supplier_name: editData.supplier_name,
          annual_speed: editData.annual_speed,
          industry: editData.industry,
          industry_subcategory: editData.industry_subcategory,
          commodity: editData.commodity,
          recommended_scope_3_category: editData.recommended_scope_3_category,
      };
  } else {
      return {
          facility_id: '',
          start_date: new Date(),
          end_date: new Date(),
          location: '',
          supplier_name: '',
          annual_speed: '',
          industry: '',
          industry_subcategory: '',
          commodity: '',
          recommended_scope_3_category: '',
      };
  }
}

getInitialValuesForFs(isEdit: boolean, editData:any) {
  if (isEdit) {
      return {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          address: editData.address,
          gas: editData.gas,
          new_unit_charge: editData.new_unit_charge,
          new_unit_capacity: editData.new_unit_capacity,
          existing_unit_recharge : editData.existing_unit_recharge,
          disposed_unit_capacity: editData.disposed_unit_capacity,
          disposed_unit_charge: editData.disposed_unit_charge,
         
      };
  } else {
      return {
          facility_id: '',
          start_date: new Date(),
          end_date: new Date(),
          address: '',
          gas: '',
          new_unit_charge: '',
          new_unit_capacity: '',
          existing_unit_recharge: '',
          disposed_unit_capacity: '',
          disposed_unit_charge: '',

      };
  }
}
getInitialValuesForSteam(isEdit: boolean, editData:any) {
  if (isEdit) {
      return {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          address: editData.address,
          description: editData.description,
          source_id:editData.source_id,
          source_area: editData.source_area,
          fuel_type: editData.fuel_type,
          boiler_efficiency : editData.boiler_efficiency,
          steam_purchased: editData.steam_purchased,
          co2_factor: editData.co2_factor,
          ch4_factor: editData.ch4_factor,
          n2o_factor: editData.n2o_factor,
          
      };
  } else {
      return {
          facility_id: '',
          start_date: new Date(),
          end_date: new Date(),
          address: '',
          description: '',
          source_id:'',
          source_area: '',
          fuel_type: '',
          boiler_efficiency: '',
          steam_purchased: '',
          co2_factor: '',
          ch4_factor: '',
          n2o_factor: '',
        
      };
  }
}
getInitialValuesForBoundaryQuestions(isEdit: boolean, editData:any) {
  if (isEdit) {
      return {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          location: editData.location,
          description: editData.description,
          stationary_combustion: editData.stationary_combustion,
          mobile_sources: editData.mobile_sources,
          fire_suppression : editData.fire_suppression,
          purchased_gases: editData.steam_purchased,
          electricity: editData.electricity,
          steam: editData.steam,
          markbased_emissionf: editData.markbased_emissionf,
          business_travel: editData.business_travel,
          employe_commuting: editData.employe_commuting,
          upstrem_tf_distribution: editData.upstrem_tf_distribution,
          waste_gemration_operations: editData.waste_gemration_operations,
          offsets: editData.offsets,
    
      };
  } else {
      return {
          facility_id: '',
          start_date: new Date(),
          end_date: new Date(),
          location: '',
          stationary_combustion: '',
          mobile_sources: '',
          fire_suppression: '',
          purchased_gases: '',
          electricity: '',
          steam: '',
          markbased_emissionf: '',
          business_travel: '',
          employe_commuting: '',
          upstrem_tf_distribution: '',
          waste_gemration_operations: '',
          offsets: '',
        
      };
  }
}

  getTableHeaderDataByType(type:string) {
    switch (type) {
      case 's1_stationaryCombustion':
          return [
          'Facility ID',
          'Start Date',
          'End Date',
          'Custom Emission Factors',
          'Location (Country)',
          'Fuel',
          'Amount of fuel',
          'Units (e.g., kg or kWh)'
        ];
      case 's1_mobileCombustion':
        return [
          'Start Date',
          'End Date',
          'Description',
          'Facility ID',
          'Location (Country)',
          'Miles Traveled',
          'Fuel Source',
          'Vehicle Type',
          'Activity Amount',
          'Unit of Fuel Amount'
        ];
      case 's1_refrigerants':
        return [
          'Start Date',
          'End Date',
          "Facility ID",
          "Location (Country)",
          {
            name: 'Equipment and Refrigerant Type',
            children: [
              'Type of Air Conditioning and Refrigeration Equipment',
              'Refrigerant Used'
            ]
          },
          {
            name: 'Decrease in Inventory (kilograms)',
            children: [
              'Refrigerant inventory (in storage, not equipment) at the beginning of the year',
              'Refrigerant inventory (in storage, not equipment) at the end of the year'
            ]
          },
          {
            name: 'Purchases/Acquisitions of Refrigerant (kilograms)',
            children: [
              'Refrigerant purchased from producers/ distributors',
              'Refrigerant returned by equipment users',
              'Refrigerant returned after off-site recycling or reclamation'
            ]
          },
          {
            name: 'Sales/Disbursements of Refrigerant (kilograms)',
            children: [
              '"Refrigerant charged into equipment* If not known, see steps A1 to A4 for a default value"',
              'Refrigerant delivered to equipment users in containers',
              'Refrigerant returned to refrigerant producers',
              'Refrigerant sent off-site for recycling or reclamation',
              'Refrigerant sent off-site for destruction'
            ]
          },
          {
            name: 'Refrigerant charged into equipment (kilograms)',
            children: [
              'Nameplate Capacity of Partially Charged Equipment',
              'Density or Pressure** of partial charge',
              'Density or Pressure** of full charge'
            ]
          }
        ];
        case 's2_purchasedElectricity':
        return [
          'Start Date',
          'End Date',
          "Facility ID",
          "Location (Country)",
          "Amount of Electricity Consumption",
          "Units",
          "Calculation Approach",
          "Type of Emission Factor",
          "Custom Emission Factor"
        ];
        case 's1_fireSuppression':
          return [
            "Facility ID",
            'Start Date',
            'End Date',
            'Location (Country)',         
            'Gas Type',
            'New Units - Charge (lb)',
            'New Units - Capacity (lb)',
            'Existing Units - Recharge (lb)',
            'Disposed Units - Capacity (lb)',
            'Disposed Units - Charge (lb)',
          ]
          case 's2_steam':
          return [
            "Facility ID",
            'Start Date',
            'End Date',
            'Location (Country)',
            'Source ID',
            'Source Area (sq ft)',
             'Fuel Type',
            'Boiler Efficeny (%)',
            'Steam Purchesed (mmBtu)',
            'CO2 Factor (kg/mmBtu)',
            'CH4 Factor (g/mmBtu)',
            'N20 Factor (g/mmBtu)',

          ]
        case 's3_transportation':
        return [
          "Facility ID",
          'Start Date',
          'End Date',
          "Description",
          "Category",
          "Location (Country)",
          "Emission Factor Dataset",
          "Mode of Transport",
          "Activity Type",
          "Vehicle Type",
          "Amount of Activity Type",
          "Units of Measurement",
          "Emissions (metric tons CO2e)",
          "Percentage of emissions calculated using data obtained from suppliers or value chain partners",
          "CO2 (tonnes)",
          "CH4 (tonnes)",
          "N2O (tonnes)",
          "CO2e (tonnes)",
          "Biofuel COe (tonnes)",
          "EF (kgCO2e/unit)"
        ];
        case 's3_waste':
          return [
            "Facility ID",
            'Start Date',
            'End Date',
            "Location (Country)",
            "Waste Material",
            "Disposal Method",
            "Weight",
            "Unit",
   
          ];
          case 'offsets':
            return [
              "Facility ID",
              'Start Date',
              'End Date',
              "Location (Country)",
              "Offset SCope/Category",
              "Offset Purchased(Metaric Tones CO2e)"
            ];
            case 'purchase_commodity':
            return [
              "Facility ID",
              'Start Date',
              'End Date',
              "Location (Country)",
              "Tire1 Supplier Name Or Purchasing Category ",
              "Annual Speed ($)",
              "Industry",
              "Industry Subcategory",
              "Commodity",
              "Recommended Scope 3 Category",
          
            ];
            case 'boundary_equations':
              return [
                "Facility ID",
                'Start Date',
                'End Date',
                "Location (Country)",
                "Stationary Combustion ",
                "Mobile Sources",
                "IFire Suppression",
                "Purchased Gases",
                "Electricity",
                "Steam",
                "Market-Based Emission Factors ",
                "Business Travel",
                "Employee Commuting",
                "Upstream Transportation and Distribution",
                "Waste Generated in Operations",
                "Offsets",
              ];
      default:
        return [];
    }
  }

  getstationaryCombustionData = (page = 1) => {
    this.getstationaryCombustionCallId = sendAPIRequest(`${configJSON.getstationaryCombustionTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getmobileCombustionData = (page = 1) => {
    this.getmobileCombustionCallId = sendAPIRequest(`${configJSON.getmobileCombustionTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getrefrigerentsData = (page = 1) => {
    this.getrefrigerentsCallId = sendAPIRequest(`${configJSON.getrefrigerentsTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getPurchasedElectricityData = (page = 1) => {
    this.getPurchasedElectricityCallId = sendAPIRequest(`${configJSON.getPurchasedElectricityTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getTransportationData = (page = 1) => {
    this.getTransportationCallId = sendAPIRequest(`${configJSON.getTransportationTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getFireSuppressionData = (page = 1) => {
    this.getFireSuppressionCallid = sendAPIRequest(`${configJSON.getfiresuppressionTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }
  getSteamData = (page = 1) => {
    this.getSteamCallId = sendAPIRequest(`${configJSON.getsteamTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

   getWasteData = (page = 1) => {
    this.getWasteCallId = sendAPIRequest(`${configJSON.getWasteTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getOffsetsData = (page = 1) => {
    this.getOffsetsCallId = sendAPIRequest(`${configJSON.getOffsetsTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }
  getPurchaseCommodityData = (page = 1) => {
    this.getTransportationCallId = sendAPIRequest(`${configJSON.getTransportationTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }
 

  handleSubmitCurrentData = (currentData:any, index:string, values:any) => {
    
    let updatedData = currentData;
    if (updatedData[index].id) {
      updatedData[index] = { ...values, id: updatedData[index].id };
    } else {
      updatedData[index] = { ...values };
    }
    return updatedData;
  }

  handleScSubmission = (values:any, onCloseCfModal:(key:string)=>void) => {
   
    if(this.state.isEditCfStationaryCombustionForm){
      const currentData = [...this.state.CfStationaryCombustionTableData];
      const index = this.state.editCfStationaryCombustionDataIndex.ind;

      const cData = this.handleSubmitCurrentData(currentData, index, values);
      this.setState({CfStationaryCombustionTableData: cData});
    }
    else{
      this.setState({CfStationaryCombustionTableData: [...this.state.CfStationaryCombustionTableData, values]})
    }
    
    this.setState({isEditCfStationaryCombustionForm: false});
    onCloseCfModal("s1_stationaryCombustion");
  }

  handleFsSubmission = (values:any, onCloseCfModal:(key:string)=>void) => {
    if(this.state.isEditCfFireSuppresionForm){
      const currentData = [...this.state.cfFireSuppresionTableData];
      const index = this.state.editFireSuppresionDataIndex.ind;
      
      const cData = this.handleSubmitCurrentData(currentData, index, values);
      this.setState({cfFireSuppresionTableData: cData});
      
    }
    else {
      this.setState({cfFireSuppresionTableData: [...this.state.cfFireSuppresionTableData, values]})
    }
    
    this.setState({isEditCfFireSuppresionForm: false});
    onCloseCfModal("s1_fireSuppression");

  }
  handleStmSubmission = (values:any, onCloseCfModal:(key:string)=>void) => {
    
    if(this.state.isEditCfSteamForm){
      const currentData = [...this.state.cfSteamTableData];
      const index = this.state.editSteamDataIndex.ind;
      
      const cData = this.handleSubmitCurrentData(currentData, index, values);
      this.setState({cfSteamTableData: cData});
      
    }
    else {
      this.setState({cfSteamTableData: [...this.state.cfSteamTableData, values]})
    }
    
    this.setState({isEditCfSteamForm: false});
    onCloseCfModal("s2_steam");

  }

  handleOffsetsSubmission = (values:any, onCloseCfModal:(key:string)=>void) => {
    
    if(this.state.isEditCfOffsetsForm){
      const currentData = [...this.state.cfOffsetsTableData];
      const index = this.state.editOffsetsDataIndex.ind;
      
      const cData = this.handleSubmitCurrentData(currentData, index, values);
      this.setState({cfOffsetsTableData: cData});
      
    }
    else {
      this.setState({cfOffsetsTableData: [...this.state.cfOffsetsTableData, values]})
      
    }
    
    this.setState({isEditCfOffsetsForm: false});
    onCloseCfModal("offsets");

  }

  handleWasteSubmission = (values:any, onCloseCfModal:(key:string)=>void) => {
    
    if(this.state.isEditCfWasteForm){
      const currentData = [...this.state.cfWasteTableData];
      const index = this.state.editWasteDataIndex.ind;
      
      const cData = this.handleSubmitCurrentData(currentData, index, values);
      this.setState({cfWasteTableData: cData});
      
    }
    else {
      this.setState({cfWasteTableData: [...this.state.cfWasteTableData, values]})
      
    }
    this.setState({isEditCfWasteForm: false});
    onCloseCfModal("s3_waste");

  }

  handleFormSubmission = (activeTab:string, values:any, onCloseCfModal:(key:string)=>void) => {
    switch (activeTab) {
      case 's1_stationaryCombustion':
        this.handleScSubmission(values, onCloseCfModal);
      break;
      case 's1_mobileCombustion':
        if(this.state.isEditCfMobileCombustionForm){
          const currentData = [...this.state.cfMobileCombustionTableData];
          const index = this.state.editCfMobileCombustionDataIndex.ind;
          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfMobileCombustionTableData: cData});
          
        }
        else {
          this.setState({cfMobileCombustionTableData: [...this.state.cfMobileCombustionTableData, values]})
        }
        
        this.setState({isEditCfMobileCombustionForm: false});
        onCloseCfModal("s1_mobileCombustion");
      break;  
      case "s1_refrigerants":
        if(this.state.isEditCfRefrigerentsForm){
          const currentData = [...this.state.cfRefrigerentsTableData];
          const index = this.state.editCfRefrigerentsDataIndex.ind;

          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfRefrigerentsTableData: cData});
        }
        else{
          this.setState({cfRefrigerentsTableData: [...this.state.cfRefrigerentsTableData, values]})
        }
        
        this.setState({isEditCfRefrigerentsForm: false});
        onCloseCfModal("s1_refrigerants");
      break;   
      case "s2_purchasedElectricity":
        if(this.state.isEditCfPurchasedElectricityForm){
          const currentData = [...this.state.cfPurchasedElectricityTableData];
          const index = this.state.editCfPurchasedElectricityDataIndex.ind;

          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfPurchasedElectricityTableData: cData});
        }
        else{
          this.setState({cfPurchasedElectricityTableData: [...this.state.cfPurchasedElectricityTableData, values]})
        }
        
        this.setState({isEditCfPurchasedElectricityForm: false});
        onCloseCfModal("s2_purchasedElectricity");
      break;  
      case "s3_transportation": 
        if(this.state.isEditCfTransportationForm){
          const currentData = [...this.state.cfTransportationTableData];
          const index = this.state.editCfTransportationDataIndex.ind;

          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfTransportationTableData: cData});
        }
        else{
          this.setState({cfTransportationTableData: [...this.state.cfTransportationTableData, values]})
        }
        
        this.setState({isEditCfTransportationForm: false});
        onCloseCfModal("s3_transportation");
      break;
      case 's1_fireSuppression':
        this.handleFsSubmission(values, onCloseCfModal);
      break; 
      case 's2_steam':
        this.handleStmSubmission(values, onCloseCfModal);
      break; 
      case 'offsets':
        this.handleOffsetsSubmission(values, onCloseCfModal);
      break; 
      case 's3_waste':
        this.handleWasteSubmission(values, onCloseCfModal);
      break; 
    }

    this.setState({SaveDraftObserver: true});
    toast.success("Data Added Successfully.");
  };

  handleDeleteData = (index:number) => {

    switch (this.state.cfNavActivate) {
      case "s1_stationaryCombustion":
        
        const currentRowSc = this.state.CfStationaryCombustionTableData.find((data: any, i: number) => i == index)

        currentRowSc.id && this.setState({scDeleteId: [...this.state.scDeleteId, currentRowSc.id]})
        this.setState(prevState => ({
          CfStationaryCombustionTableData: prevState.CfStationaryCombustionTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s1_mobileCombustion":
        const currentRowMc = this.state.cfMobileCombustionTableData.find((data: any, i: number) => i == index)

        currentRowMc.id && this.setState({mcDeleteId: [...this.state.mcDeleteId, currentRowMc.id]})

        this.setState(prevState => ({
          cfMobileCombustionTableData: prevState.cfMobileCombustionTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s1_refrigerants":
        const currentRowRf = this.state.cfRefrigerentsTableData.find((data: any, i: number) => i == index)

        currentRowRf.id && this.setState({rfDeleteID: [...this.state.rfDeleteID, currentRowRf.id]})

        this.setState(prevState => ({
          cfRefrigerentsTableData: prevState.cfRefrigerentsTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s2_purchasedElectricity":
        const currentRowPe = this.state.cfPurchasedElectricityTableData.find((data: any, i: number) => i == index)

        currentRowPe.id && this.setState({peDeleteId: [...this.state.peDeleteId, currentRowPe.id]})

        this.setState(prevState => ({
          cfPurchasedElectricityTableData: prevState.cfPurchasedElectricityTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s3_transportation":
        const currentRowTs = this.state.cfTransportationTableData.find((data: any, i: number) => i == index)

        currentRowTs.id && this.setState({tsDeleteId: [...this.state.tsDeleteId, currentRowTs.id]})

        this.setState(prevState => ({
          cfTransportationTableData: prevState.cfTransportationTableData.filter((data: any, i: number) => i !== index),
        }));

      break;
      case "s1_fireSuppression":
        const currentRowFs = this.state.cfFireSuppresionTableData.find((data: any, i: number) => i == index)

        currentRowFs.id && this.setState({fsDeleteId: [...this.state.fsDeleteId, currentRowFs.id]})

        this.setState(prevState => ({
          cfFireSuppresionTableData: prevState.cfFireSuppresionTableData.filter((data: any, i: number) => i !== index),
        }));

      break;
      case "s2_steam":
        const currentRowStm = this.state.cfSteamTableData.find((data: any, i: number) => i == index)

        currentRowStm.id && this.setState({steamDeleteId: [...this.state.steamDeleteId, currentRowStm.id]})

        this.setState(prevState => ({
          cfSteamTableData: prevState.cfSteamTableData.filter((data: any, i: number) => i !== index),
        }));

      break;
      case "offsets":
        const currentRowOffsets = this.state.cfOffsetsTableData.find((data: any, i: number) => i == index)

        currentRowOffsets.id && this.setState({offsetsDeleteID: [...this.state.offsetsDeleteID, currentRowOffsets.id]})

        this.setState(prevState => ({
          cfOffsetsTableData: prevState.cfOffsetsTableData.filter((data: any, i: number) => i !== index),
        }));

      break;
      case "s3_waste":
        const currentRowWaste = this.state.cfWasteTableData.find((data: any, i: number) => i == index)

        currentRowWaste.id && this.setState({wasteDeletedID: [...this.state.wasteDeletedID, currentRowWaste.id]})

        this.setState(prevState => ({
          cfWasteTableData: prevState.cfWasteTableData.filter((data: any, i: number) => i !== index),
        }));

      break;
    }
   
    this.setState({SaveDraftObserver: true});
  }

  showBoolean(givenValue: boolean | string) {
    let orginalValue = this.checkBoolean(givenValue);
    let upValue = Boolean(orginalValue)
    let value
    if (upValue) {
      value = 'Yes';
    } else if (!upValue) {
      value = 'No';
    } else {
      return null
    }

    return value;
  }

  checkBoolean(givenValue: boolean | string) {
    if (typeof givenValue === 'boolean') {
      return givenValue;
    } else {
      let booleanValue;
      if (givenValue.toLowerCase() === 'yes' || givenValue.toLowerCase() == 'true') {
        booleanValue = true;
      } else if (givenValue.toLowerCase() === 'no' || givenValue.toLowerCase() == 'false') {
        booleanValue = false;
      } else {
        return null
      }

      return booleanValue;
    }
  }

  getNavigationMessage = (route: string, props: any, payload?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    
    this.send(message);
  }

  getFuelListStationary = (event: any, callback: Function) => {
    let fuelRes1 = this.state.fuelDropdownDataEfExist.sort()
    let fuelRes2 = this.state.fuelDropdownData.sort()
    let fuelList = event.target.value == "true" ? fuelRes1 : fuelRes2
    
    this.setState({
      fuelListStationary: fuelList
    }, () => callback());
  }

  stationaryCombustionFuelSource = (event: any, callback: Function)=>{
    let { stationaryunitsDropdownData } = this.state
    const selectedType = event.target.value;
    const fuelSourceData = this.state.newfuelDropDown.find((item:any) => item.value === selectedType).type;
    const fuelSource = stationaryunitsDropdownData.find(item => item.type === fuelSourceData);
    const fuelUnitList = fuelSource ? fuelSource.units : [];
   
    this.setState({
      fuelSourceUnits: fuelUnitList,
    }, () => callback());
    
  }
 
  mobileCombustionFuelType = (event: any, callback: Function) => {
    let { mobileCombustionDropdownData } = this.state
    let fuelSourceList = event.target.value == "Custom emission factor" ? mobileCombustionDropdownData.fuel_source_cef : mobileCombustionDropdownData.fuel_source;
    let vehicleTypeList = event.target.value == "Custom emission factor" ? [{ fuel_source: 'Example S1 - Mobile', value: '#N/A' }] : []

    let vehicleFuleType = mobileCombustionDropdownData.fuel_source.filter((data: any) => event.target.value == data.vehicle_type).sort((a: any, b: any) => a.value.localeCompare(b.value, 'en', { sensitivity: 'base' }));
    
    this.setState({
      fuelSourceListMobile: fuelSourceList,
      vehicleTypeListMobile: vehicleTypeList,
   
      fuelTypeListMobile: vehicleFuleType
    }, () => callback());
  }
  purchasedEmissionFactorValue = (e: any, values: any, setFieldValue: any) => {
    let filterList = ['0']
    if (e.target.value == 'Custom emission factor') {
      if (values.calculation_approach == 'Heat/Steam') {
        filterList = ['Example S2 - heat']
      } else {
        filterList = ['Example S2']
      }
    }
    this.setState({
      filterPurchasedEmissionFactor: filterList
    }, () => {
      setFieldValue('custom_emission_factor', this.setDefaultValue(this.state.filterPurchasedEmissionFactor))
    });
  }

  transportationFactorDataset = (event: any, setFieldValue: any) => {
    let { transportDropdownData } = this.state
    let activityTypeList = event.target.value == "Custom emission factor" ? transportDropdownData.activity_type_cef : transportDropdownData.activity_type
    this.setState({
      activityTypeList: activityTypeList,
    }, () => {
      setFieldValue('activity_type', this.setDefaultValue(this.state.activityTypeList));
      setFieldValue('mode_of_transport', '');
      setFieldValue('vehicle_type', '');
      setFieldValue('units_of_measurement', '');
    });
  }

  transportationVehicleType = (event: any, values: any, setFieldValue: any) => {
    let vehicleTypeListTransport = this.state.transportDropdownData.vehicle_type.filter((data: any) => event.target.value == data.mode_of_transport && data.emission_factor_dataset == values.emission_factor_dataset).sort((a: any, b: any) => a.value.localeCompare(b.value, 'en', { sensitivity: 'base' }))
    this.setState({
      vehicleTypeListTransport
    }, () => {
      setFieldValue('vehicle_type', this.setDefaultValue(this.state.vehicleTypeListTransport))
    });
  }

  transportUnitsOfMeasurement = (event: any) => {
    let measurementUnit = [
      { activity_type: 'Distance', value: 'nautical mile' },
      { activity_type: 'Distance', value: 'mile' },
      { activity_type: 'Distance', value: 'km' },
      { activity_type: 'Passenger Distance', value: 'passenger-mile' },
      { activity_type: 'Passenger Distance', value: 'passenger-km' },
      { activity_type: 'Vehicle Distance', value: 'Vehicle-mile' },
      { activity_type: 'Vehicle Distance', value: 'vehicle-km' },
      { activity_type: 'Weight Distance', value: 'ton-mile' },
      { activity_type: 'Weight Distance', value: 'tonne-km' },
    ]
    let filterMeasurementUnit = measurementUnit.filter((data: any) => event.target.value == data.activity_type).sort((a: any, b: any) => a.value.localeCompare(b.value, 'en', { sensitivity: 'base' }))
    this.setState({
      unitsOfMeasurement: filterMeasurementUnit
    });
  }

  setDefaultValue = (setState: any) => {
    let result = typeof setState[0] == 'object' ? setState[0].value : setState[0]
    return setState.length === 1 ? result : ''
  }  

  checkedCategory = () => {
    if (this.state.selectedCategory === "") return true;
    return ['Business Travel', 'Upstream T&D', 'Employee Commute'].includes(this.state.selectedCategory as string); 
  } 
   
  handleApiResponse = (apiRequestCallId: string,responseJson: any)=>{
    switch (apiRequestCallId) {
      case this.getstationaryCombustionCallId:

        const stationaryCombustionData = responseJson.ghgs?.data?.map((data:any) => data.attributes)
        this.setState({
          CfStationaryCombustionTableData: stationaryCombustionData || [], 
          total_pages: responseJson.ghgs?.meta?.pagination?.total_pages,
          current_page: responseJson.ghgs?.meta?.pagination?.current_page,
          loading:false
        });
      break; 

      case this.getmobileCombustionCallId:
        const mcData = responseJson.mobile_combustions?.data?.map((data:any) => data.attributes)
        this.setState({
          cfMobileCombustionTableData: mcData || [],
          total_pages: responseJson.mobile_combustions?.meta?.pagination?.total_pages,
          current_page: responseJson.mobile_combustions?.meta?.pagination?.current_page,
          loading:false
        });
      break;  

      case this.getrefrigerentsCallId:
        const refrigerentsData = responseJson.refrigerants?.data?.map((data:any) => data.attributes)
        this.setState({
          cfRefrigerentsTableData: refrigerentsData || [],
          total_pages: responseJson.refrigerants?.meta?.pagination?.total_pages,
          current_page: responseJson.refrigerants?.meta?.pagination?.current_page,
          loading:false
        });
      break;

      case this.getPurchasedElectricityCallId:
        const purchasedElectricityData = responseJson.ghgs?.data?.map((data:any) => data.attributes)
        this.setState({
          cfPurchasedElectricityTableData: purchasedElectricityData || [],
          total_pages: responseJson.ghgs?.meta?.pagination?.total_pages,
          current_page: responseJson.ghgs?.meta?.pagination?.current_page,
          loading:false
        });
      break;  

      case this.getTransportationCallId:
        const transportationData = responseJson.transportations?.data?.map((data:any) => data.attributes)
        this.setState({
          cfTransportationTableData: transportationData || [],
          total_pages: responseJson.transportations?.meta?.pagination?.total_pages,
          current_page: responseJson.transportations?.meta?.pagination?.current_page,
          loading:false
        });
      break;
      
      case this.getFireSuppressionCallid:
        const firesuppressionData = responseJson.steam_suppressions_combustions?.map((data:any) => data)  
        this.setState({
          cfFireSuppresionTableData: firesuppressionData || [],
          total_pages: responseJson?.meta?.pagination?.total_pages,
          current_page: responseJson?.meta?.pagination?.current_page,
          loading:false
        });
      break;
      case this.getSteamCallId:
        const steamData = responseJson.steam_suppressions_combustions?.map((data:any) => data)  
        this.setState({
          cfSteamTableData: steamData || [],
          total_pages: responseJson?.meta?.pagination?.total_pages,
          current_page: responseJson?.meta?.pagination?.current_page,
          loading:false
        });
      break;

      case this.getOffsetsCallId:
        const offsetsData = responseJson.offsets_combustions?.map((data:any) => data)  
        this.setState({
          cfOffsetsTableData: offsetsData || [],
          total_pages: responseJson?.meta?.pagination?.total_pages,
          current_page: responseJson?.meta?.pagination?.current_page,
          loading:false
        });
      break;

      case this.getWasteCallId:
        const wasteData = responseJson.waste_combustions?.map((data:any) => data)  
        this.setState({
          cfWasteTableData: wasteData || [],
          total_pages: responseJson?.meta?.pagination?.total_pages,
          current_page: responseJson?.meta?.pagination?.current_page,
          loading:false
        });
      break;

      case this.draftDataScCallID:
        this.setState({ scDeleteId: [] });
        this.getstationaryCombustionData();
        toast.success("Data Saved As Draft.")
      break;

      case this.draftDataMcCallID:
        this.setState({  mcDeleteId: [] });
        this.getmobileCombustionData();
        toast.success("Data Saved As Draft.")
      break;

      case this.draftDataRfCallID:
        this.setState({  rfDeleteID: [] });
        this.getrefrigerentsData();
        toast.success("Data Saved As Draft.")
      break;

      case this.draftDataPeCallID:
        this.setState({ peDeleteId: [] });
        this.getPurchasedElectricityData();
        toast.success("Data Saved As Draft.")
      break;
      
      case this.draftDataTransportationsCallID:
        this.setState({ tsDeleteId: [] });
        this.getTransportationData();
        toast.success("Data Saved As Draft.")
       break;
       
       case this.draftDataSFsCallID:
        this.setState({ fsDeleteId: [] });
        this.getFireSuppressionData();
        toast.success("Data Saved As Draft.")
       break;
       case this.draftDataStmCallID:
        this.setState({ steamDeleteId: [] });
        this.getSteamData();
        toast.success("Data Saved As Draft.")
       break;
       case this.draftDataOffsetsCallID: 
        this.setState({ offsetsDeleteID: [] });
        this.getOffsetsData();
        toast.success("Data Saved As Draft.")
       break;

       case this.draftDataWasteCallID: 
        this.setState({ wasteDeletedID: [] });
        this.getWasteData();
        toast.success("Data Saved As Draft.")
       break;

      case this.submitDataScCallID:
      case this.submitDataMcCallID:
      case this.submitDataRfCallID:
      case this.submitDataPeCallID:
      case this.submitDataTsCallID:
      case this.submitDataFsCallID:  
      case this.submitDataStmCallID:
      case this.submitDataOffsetsCallID:
      case this.submitDataWasteCallID:

        toast.success("Data Saved Successfully.")
        this.setState({ 
          submitDataLoader: false, 
          pdfUrl:responseJson.url, 
          scDeleteId: [], 
          mcDeleteId: [], 
          rfDeleteID: [], 
          peDeleteId: [], 
          tsDeleteId: [],
          fsDeleteId: [],
          steamDeleteId:[],
          offsetsDeleteID:[],
          wasteDeletedID:[],

          CfStationaryCombustionTableData:[],
          cfPurchasedElectricityTableData: [],
          cfTransportationTableData: [],
          cfMobileCombustionTableData: [],
          cfRefrigerentsTableData: [], 
          cfWasteTableData:[],
          cfFireSuppresionTableData:[],
          cfSteamTableData:[],
          cfOffsetsTableData:[],

          SaveDraftObserver:false
        });
      break;
    }
      
  }


   componentDidUpdate(prevState: any) {
    if (this.state.isSaveModelOpen !== prevState.isSaveModelOpen) {
        if (this.state.isSaveModelOpen) {
          document.body.style.overflow  = "hidden"; 
        } 
        else {
          document.body.style.overflow  = "auto";         
        }
    }
    }

  // Customizable Area End

}
