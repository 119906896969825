import React from "react";
// Customizable Area Start

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Grid,
  IconButton,
  Divider,
  Button,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Slide,
  Popper,
  ClickAwayListener,
  Paper
} from '@material-ui/core'
import { styled, makeStyles } from '@material-ui/styles'
import { ArrowRight, ArrowBackIos } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import DeleteModal from '../../../components/src/DeleteModal.web'
import {
  ShareIcon,
  CommentIcon,
  ThumbGreenIcon,
  commentCount,
  ArrowDownIcon,
  StarIcon,
  StarActiveIcon,
  OyoAvatar,
  MoreHorizIcon,
  UserProfile,
  SearchIcon,
} from './assets'
import NestedMenuItem from '../../../components/src/NestedMenuItem.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import { CloseCircleIcon } from '../../../components/src/Icons.web'
import SearchFilter from '../../../components/src/Search.web'
import MediaCarousel from '../../../components/src/MediaCarousel.web'
import UserContext from '../../../components/src/UserProvider'
import Attachments from '../../../components/src/Attachments.web'
import OverlayLoader from '../../../components/src/Loader.web'
import { useWindowSize } from '../../../components/src/utils'


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import { Link } from 'react-router-dom';
// Customizable Area End

import ActivityFeedController, {
  Props,
  configJSON
} from "./ActivityFeedController";





export default class ActivityFeed extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  feedsDescription = (p:any) => {
    if(p?.body) {
      if(p?.body?.length > 455){
        return <>{p.body.substring(0, 455)}<span style={{ fontWeight: "bold"}}>{" "}See More...</span></>
      }
      else{
        return p.body
      }
    } 
    else if(p?.description?.length > 455) {
      return <>{p?.description?.substring(0, 455)}<span style={{ fontWeight: "bold"}}>{" "}See More...</span></>
    }     
    else {
      return p?.description
    } 
  }

  feedsRender = () => {
    const { navigation } = this.props

    let { feedPosts } = this.state
    return  feedPosts?.map?.((p:any) => <RoundedCard style={{ padding: '0' }} key={p?.id} variant="outlined">
          <CardHeader
            style={{ padding: '25px 20px 15px 20px' }}
            avatar={<Link to={this.props.inside == "CommunityDetail" ? `/user/${p?.created_by?.id}` : `user/${p?.created_by?.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={p?.created_by?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`user/${p?.created_by?.id}`)} /></Link>}
            title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={this.props.inside == "CommunityDetail" ? `/user/${p?.created_by?.id}` : `user/${p?.created_by?.id}`}>{p?.created_by?.name}</Link></span>}
            subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
              <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{p?.created_by?.location}</span>
              <Divider orientation="vertical" flexItem />
              <span style={{ fontSize: '0.75rem' }}>{p?.created_at?.replace(/about/g, '')}</span>
            </Grid>}
            action={<div onClick={this.openThreeDot} data-id={p?.id}>
              <IconButton style={{ backgroundColor: 'transparent' }} disableFocusRipple disableRipple>
                <img src={MoreHorizIcon} width="30px" />
              </IconButton>
            </div>}

          />

          <Divider />
          <CardContent id='cardContentId' style={{ padding: '16px 30px' }}>
            <FlexSpaceBet style={{ gap: '20px', padding: '0', alignItems: 'start' }}>
             
              <div>
                <span style={{ fontWeight: 700, fontSize: "1.021rem", marginRight: '5px' }}>
                  Community:
                </span>
                <MR5 id='viewCommunity' style={{cursor:'pointer'}} onClick={()=>this.props.navigation.history.push(`/community/${p?.community_details?.data[0]?.attributes?.id || ''}`)}>{p?.community[0]}</MR5>
                {p?.community?.length > 1 && <PlusMorePopup heading="Community" listItems={p?.community}>
                  +{p?.community?.length - 1}
                </PlusMorePopup>}
              </div>
              <div>
                <span style={{ fontWeight: 700, fontSize: "1.021rem", marginRight: '5px', color: '#000' }}>
                  Category:
                </span>
                <MR5>{p?.category[0]}</MR5>
                {p?.category?.length > 1 && <PlusMorePopup heading="Category" listItems={p.category}>
                  +{p?.category?.length - 1}
                </PlusMorePopup>}
              </div>
              <div>
                <span style={{ fontWeight: 700, fontSize: "1.021rem", marginRight: '5px', color: '#000' }}>
                  Sub Category:
                </span>
                <MR5>{p?.Sub_category[0]}</MR5>
                {p?.Sub_category?.length > 1 && <PlusMorePopup heading="Sub Category" listItems={p?.Sub_category}>
                  +{p?.Sub_category?.length - 1}
                </PlusMorePopup>}
              </div>
            </FlexSpaceBet>
          </CardContent>
          <Divider />
          <CardContent style={{ padding: '16px 30px' }}>
         
            <PostMainContent id='PostMainContentWrapper'>
            <div id='post' onClick={() => this.goToPost(p?.id)}> 
              <Typography style={{ marginBottom: '8px' }}>
                <b style={{ wordBreak: 'break-all', fontSize: '1.125rem', lineHeight: '25px', color: '#2A353F', textTransform: 'capitalize' }}>{p?.name || ""}</b>
              </Typography>
              <Typography color="textSecondary" style={{ color: 'rgba(42, 53, 63, 0.32)', fontSize: '1.125rem' }}>
                <Typography color="textSecondary" style={{ wordWrap: 'break-word', alignItems: 'justify' }}>
                  {this.feedsDescription(p)}               
                </Typography>
              </Typography>
              </div>
              <FeedCarousel>
                <MediaCarousel items={(p?.images_and_videos || []).filter((file:any) => file.type !== "application")} />
              </FeedCarousel>
              <Attachments files={(p?.images_and_videos || []).filter((file:any) => file.type === "application")} />
            </PostMainContent>
            <FlexSpaceBetween style={{ padding: 0 }}>
              <div style={{ lineHeight: '30px', display: 'flex', gap: '15px', fontSize: '0.875rem', color: '#212529', marginTop: '15px' }}>
                <img src={ThumbGreenIcon} width="30" /> <span>{p?.total_likes}</span>
                <img src={commentCount} width="30" /> <span>{p?.total_comments}</span>
              </div>
            </FlexSpaceBetween>
          </CardContent>
          <Divider />
          <CardActions id='cardActionWrapper'>
            <FlexSpaceBetween style={{ padding: '0px 0px 5px 0px' }}>
              <CardButtonWrapper>
                <LikeAPost
                  navigation={this.props.navigation}
                  id=""
                  likeable_type="BxBlockPosts::Post"
                  isLiked={Boolean(p?.like_id?.some((project: any) => Number(project?.like_by_id) === Number(this.state.userID)))}
                  post_id={p?.id}
                  like_id={p?.like_id?.find((project: any) => Number(project.like_by_id) == (this.state.userID))?.id}
                  onLikeChange={this.onLikeChange}
                  onLikeIdUpdate={this.onLikeIdUpdate}
                />

                <CardButton id='viewPost' onClick={() => this.goToPost(p?.id)} startIcon={<BtnImg src={CommentIcon} />}>Comment</CardButton>
                <CardButton id='sharePost' startIcon={<BtnImg src={ShareIcon} />} onClick={() => this.handleSharePost(p)}>Share</CardButton>

              </CardButtonWrapper>
              <div>
                <CardButton data-test-id="onStarClick" data-id={p?.id} onClick={this.handleFavoriteClick} variant="outlined" startIcon={<BtnImg src={p?.is_favourite ? StarActiveIcon : StarIcon} />} />
              </div>
            </FlexSpaceBetween>
          </CardActions>
        </RoundedCard>
        )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    let { feedPosts,hashTags } = this.state
    if (this.state.filterByFav) feedPosts = feedPosts?.filter((p: any) => p?.is_favourite)
    
    const currentPost = this.state.currentPost
    const feed = 
    <CenterRow inside={this.props.inside}>
      <FilterRowWrapper >
        <div style={{ flex: 1 }}>
          <SearchFilter data-test-id='searchPosts' searchInputValue={hashTags}  onChange={this.onFilterQueryChange} />
        </div>
        <div style={{ flex: 1 }}>
          <TextFieldRounded
            data-test-id='textfieldRoundedId'
            value={this.filterText()}
            onClick={this.handleFilterMenuOpen}
            // @ts-ignore 
            InputProps={{
              ref: this.filterRef,
              endAdornment: (<InputAdornment position="start">
                {this.filterText() && <CloseCircleIcon
                  onClick={this.clearFilters}
                  style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
                />}
                <img src={ArrowDownIcon} width="16" />
              </InputAdornment>)
            }} variant="outlined" placeholder={configJSON.labelFilterBy} />
          {/* @ts-ignore */}
          <Hidden mdDown>
            <FilterMenu
              data-test-id='filterMenuId'
              inside={this.props.inside}
              filterByMe={this.state.filterByMe}
              filterByFav={this.state.filterByFav}
              toggleFilterByMe={this.toggleFilterByMe}
              toggleFilterByFav={this.toggleFilterByFav}
              handleDateInputFocus={this.handleDateInputFocus}
              handleDateInputBlur={this.handleDateInputBlur}
              keepOpen={this.state.selectingDate}
              menuPosition={this.state.filterMenuPosition}
              onClose={this.handleFilterMenuClose}
              tagControls={{
                tags: this.state.subcategories,
                selectedTags: this.state.filterSubCategories,
                handleSelect: this.handleTagSelect,
              }}
              catControls={{
                categories: this.state.categories,
                handleSelect: this.handleCategorySelect,
                selectedCategories: this.state.filterCategories,
              }}
              dateControls={{
                handleSubmit: this.handleDateFilterSubmit,
                handleCancel: this.handleDateFilterCancel,
                handleFromDateChange: this.handleFromDateChange,
                handleToDateChange: this.handleToDateChange,
                filterFromDate: this.state.filterFromDate,
                filterToDate: this.state.filterToDate,
                minDate: this.state.filterFromDate,
                maxDate: this.state.filterToDate
              }}
            />
          </Hidden>
          <FilterMenuMobile
          data-test-id='filterMenueMobileId'
            inside={this.props.inside}
            menuPosition={this.state.filterMenuPosition}
            onClose={this.handleFilterMenuClose}
            filterByMe={this.state.filterByMe}
            filterByFav={this.state.filterByFav}
            toggleFilterByMe={this.toggleFilterByMe}
            toggleFilterByFav={this.toggleFilterByFav}

            catControls={{
              categories: this.state.categories,
              handleSelect: this.handleCategorySelect,
              selectedCategories: this.state.filterCategories,
            }}
            tagControls={{
              tags: this.state.subcategories,
              selectedTags: this.state.filterSubCategories,
              handleSelect: this.handleTagSelect,
            }}
            dateControls={{
              handleFromDateChange: this.handleFromDateChange,
              handleToDateChange: this.handleToDateChange,
              filterFromDate: this.state.filterFromDate,
              filterToDate: this.state.filterToDate,
              minDate: this.state.filterFromDate,
              maxDate: this.state.filterToDate
            }}
            disabled={ this.state.filterFromDate && this.state.filterToDate?false:true }
          />
        </div>




      </FilterRowWrapper>
      {this.props.inside !== 'UserProfile' && <NewPostCard data-test-id='createAPost' profilePic={this.context?.profile_pic} onClick={() => this.hanldeNavigateRequestPage('PostCreation')} />}
      {!this.state.loadingPosts && feedPosts?.length === 0 && <h3 style={{ marginTop: '40px', textAlign: 'center' }}>No Match Found</h3>}
      {this.state.deletePostId && <DeleteModal onClose={this.closeDeleteModal} onSubmit={this.handlePostDeleteConfirm} />}
      
      <StyledPopper
        id="threedot-menu"
        open={Boolean(this.state.threedotAnchorEl)}
        anchorEl={this.state.threedotAnchorEl}
        role={undefined}
        transition
        placement="bottom-start"
      >
      <ClickAwayListener onClickAway={this.handleThreeDotClose}>
        <Paper>          
          <MenuItem 
          disabled={this.state.currentPost?.created_by?.id == this.context.id}
          data-test-id='ReportAPost'
          onClick={this.handlePostReport}>Report This Listing</MenuItem>
            <MenuItem
              disabled={!this.state.currentPost?.status?.is_editable}
              data-test-id='editAPost'
              onClick={this.handlePostEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              disabled={!this.state.currentPost?.is_deletable}
              data-test-id='DeleteAPost'
              onClick={this.handlePostDelete}
            >
              Delete
            </MenuItem>
            <MenuItem
              data-test-id='raiseARequest'
              disabled={this.state.currentPost?.created_by?.id == this.context.id}
              onClick={this.handleRaiseRequest}
            >
              Raise A Request
            </MenuItem>
        </Paper>
          </ClickAwayListener>
      </StyledPopper>  
      <InfiniteScroll
        data-test-id='infinitscrollId'
        dataLength={feedPosts?.length} //This is important field to render the next data
        next={this.loadNextPage}
        hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingPosts}
        // Show only when this.state.loadingPosts component is not rendered
        loader={!(!this.state.loadingPosts && feedPosts?.length === 0) && <LoadingSpinner />}
      >
       {this.feedsRender()}
      </InfiniteScroll>
      <OverlayLoader loading={this.state.loadingPosts || this.state.postDeleteCallId} />

    </CenterRow>
    if (this.props.inside) return feed
    // Customizable Area End
    return (
      // Customizable Area Start
      <DashboardLayout navigation={navigation} token={this.state.token} showChatMobileView={this.state.showChatMobileView} isActivityFeed={true} changeChatMobileView={this.changeChatMobileView}>
        <PageWrapper inside={this.props.inside}>
          {feed}
        </PageWrapper>
      </DashboardLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

ActivityFeedController.contextType = UserContext

// ************* small components (only view, don't add controller logic here ) **********//

export const NewPostCard = (props: { onClick: () => any, profilePic: string }) => (<NewPostCardWrapper {...props} variant="outlined">
  <Avatar src={props.profilePic || UserProfile} />
  <Typography>{configJSON.labelWriteAPost}</Typography>
</NewPostCardWrapper>)


const FilterMenuMobile = (props: any) => {
  const [menu, setMenu] = React.useState("main")
  const onDateMenuOpen = () => setMenu("date")
  const onCategoriesMenuOpen = () => setMenu("categories")
  const onTagsMenuOpen = () => setMenu("tags")
  const handleClose = () => {
    setMenu("main")
    props.onClose()
  }
  const handleMenuBack = () => {
    setMenu("main")
  }
  const { dateControls, catControls, tagControls } = props
  const windowSize = useWindowSize()
  if (windowSize?.width > 1024) return <></>
  const MainMenu = <MobileFilterList>
    {props.inside !== 'UserProfile' && <ListItem button onClick={props.toggleFilterByMe} selected={props.filterByMe}>
      <ListItemText>Created by Me</ListItemText>
    </ListItem>}
    <ListItem button onClick={props.toggleFilterByFav} selected={props.filterByFav}>
      <ListItemText>Favourite</ListItemText>
    </ListItem>
    <ListItem button onClick={onCategoriesMenuOpen}>
      <FlexSpaceBetween>
        <div>Based on Category</div>
        <ArrowRight />
      </FlexSpaceBetween>
    </ListItem>
    <ListItem data-test-id='basedOnTagsId' button onClick={onTagsMenuOpen}>
      <FlexSpaceBetween>
        <div>Based on Tags</div>
        <ArrowRight />
      </FlexSpaceBetween>
    </ListItem>
    <ListItem data-test-id='openDateMenu' button onClick={onDateMenuOpen}>
      <FlexSpaceBetween>
        <div>Based on Date</div>
        <ArrowRight />
      </FlexSpaceBetween>
    </ListItem>
  </MobileFilterList>
  const DateMenu = <FilterByDateWrapper>
    <FilterByDateRow>
      Choose Date
    </FilterByDateRow>
    <FilterByDateRow>
      <span style={{ flex: 1 }}>From</span>
      <div style={{ flex: 3 }}>
         <DatePicker
          clearable
          inputVariant="outlined"
          onChange={dateControls.handleFromDateChange}
          value={dateControls.filterFromDate}
          style={{width: '100%'}}
          format="dd-MM-yyyy"
          autoOk={true}
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              {dateControls.filterFromDate && <CloseCircleIcon
                onClick={props.clearFromDate}
                style={{ width: '24px', cursor: 'pointer' }}
              />}
            </InputAdornment>)
          }}
          inputProps={{ placeholder: 'Select',  onFocus: props.handleDateInputFocus,
          onBlur: props.handleDateInputBlur, }}
          maxDate={new Date()}
          initialFocusedDate={dateControls.filterToDate}
        />
      </div>
    </FilterByDateRow>
    <FilterByDateRow>
      <span style={{ flex: 1 }}>To</span>
      <div style={{ flex: 3 }}>
        {/* @ts-ignore */}
        <DatePicker
          clearable
          inputVariant="outlined"
          onChange={dateControls.handleToDateChange}
          value={dateControls.filterToDate}
          style={{width: '100%'}}
          format="dd-MM-yyyy"
          autoOk={true}
          maxDate={new Date()}
          minDate={dateControls.filterFromDate || new Date()}
          initialFocusedDate={dateControls.filterFromDate}
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              {dateControls.filterToDate && <CloseCircleIcon
                onClick={props.clearToDate}
                style={{ width: '24px', cursor: 'pointer' }}
              />}
            </InputAdornment>)
          }}
          inputProps={{ placeholder: 'Select', onFocus: props.handleDateInputFocus,
          onBlur: props.handleDateInputBlur }}
        />
      </div>
    </FilterByDateRow>
  </FilterByDateWrapper>
  return <Slide direction="up" in={!!props.menuPosition} mountOnEnter unmountOnExit>
    <MobileFilterBody>
      <div style={{ margin: '10px 10px 0 10px', }}>
        <FlexSpaceBetween>
          <div style={{ display: 'flex', gap: '10px' }}>
            {menu !== "main" && <Button onClick={handleMenuBack}>
              <ArrowBackIos />
            </Button>}
            <Typography variant="h5">
              {menu === "main" && "Filter"}
              {menu === "categories" && "Filter By Category"}
              {menu === "tags" && "Filter By Tags"}
              {menu === "date" && "Filter By Date"}
            </Typography>
          </div>
          <Button onClick={handleClose}>
            <CloseCircleIcon />
          </Button>
        </FlexSpaceBetween>
        {menu === "main" && MainMenu}
        {menu === "date" && DateMenu}
        {menu === "categories" && <SearchMobileMenu
          items={catControls.categories}
          selectedIds={catControls.selectedCategories}
          label="Based on category"
          selectHandler={catControls.handleSelect}
        />}
        {menu === "tags" && <SearchMobileMenu
          items={tagControls.tags}
          selectedIds={tagControls.selectedTags}
          label="Based on tags"
          selectHandler={tagControls.handleSelect}
        />}
        <Button
          style={{ position: 'absolute', bottom: '10px', width:'95%' }}
          variant="contained"
          fullWidth
          color="primary"
          disableElevation
          onClick={handleClose}
          disabled={ menu==="date" && props.disabled }
        >
          Apply
        </Button>
      </div>
    </MobileFilterBody>
  </Slide>
}

const MobileFilterList = styled(List)({
  maxHeight: 'calc( 100vh - 150px )',
  overflowY: 'auto',
  lineHeight:'2rem'
})

export const SearchMobileMenu = (props: any) => {
  const { items, selectedIds, selectHandler, label } = props
  const [search, setSearch] = React.useState("")
  const [focus, setFocus] = React.useState(false)
  const [filteredItems, setFilteredItems] = React.useState<any[]>(items);

  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value
    setSearch(value)
  }

  React.useEffect(() => {
    setFilteredItems(items.filter(
      (item: any) => item.name.toLowerCase().includes(search.toLowerCase())
    )
    )
  }, [search])

  const stopPropagation = (e: React.KeyboardEvent<any>) => e.stopPropagation()
  return <MobileFilterList>
    <li onKeyDown={stopPropagation} >
      <TextField
        data-tes-id='feedsSearch'
        InputProps={{
          startAdornment: (<InputAdornment position="start">
            <img src={SearchIcon} width={20} style={{ paddingLeft: '15px' }} />
          </InputAdornment>
          ),
        }}
        fullWidth
        style={{ marginTop: '5px 10px' }}
        inputProps={{ style: { padding: '10px 10px' } }}
        onFocus={onFocus}
        onBlur={onBlur}
        value={search}
        onChange={onSearchChange}
        placeholder="Search" />
    </li>
    {items?.length === 0 && "Loading"}
    {filteredItems?.length === 0 && <div style={{ padding: '20px' }}>No match found</div>}
    {
      filteredItems?.map(
        (cat: any) => (
          <ReverseMenuItem onClick={selectHandler(cat.id)} key={cat.id}>
            <Checkbox
              onChange={selectHandler(cat.id)}
              checked={selectedIds.indexOf(cat.id) !== -1}
              icon={icon}
              checkedIcon={checkedIcon}
              color="primary"
              style={{ marginRight: 8 }}
            />
            {cat.name}
          </ReverseMenuItem>
        ))
    }
  </MobileFilterList>
}

const MobileFilterBody = styled('div')({
  width: '100%',
  height: '100%',
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'white',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,
})

interface FilterMenuProps {
  filterByMe: boolean,
  filterByFav: boolean,
  toggleFilterByMe: () => void,
  toggleFilterByFav: () => void,
  menuPosition: {
    top: number,
    left: number,
  } | undefined
  onClose: () => void,
  handleDateInputFocus: any,
  handleDateInputBlur: any,
  keepOpen: any,
  tagControls: {
    tags: any[],
    selectedTags: number[],
    handleSelect: (id: number) => () => void,
  },
  catControls: {
    categories: any[],
    selectedCategories: number[],
    handleSelect: (id: number) => (e: any) => void,
  },
  dateControls: {
    handleFromDateChange: any,
    handleToDateChange: any,
    handleSubmit: () => void,
    handleCancel: () => void,
    filterFromDate?: Date | null,
    filterToDate?: Date | null,
    maxDate?: Date | null,
    minDate?: Date | null,
  },
  inside?: string
}

const FilterMenu = ({ menuPosition, onClose, tagControls, handleDateInputFocus, handleDateInputBlur, keepOpen, dateControls, catControls, ...props }: FilterMenuProps) => {
  const { categories } = catControls
  const { tags } = tagControls
  const [filteredCats, setFilteredCats] = React.useState<any[]>(categories)
  const [nestedMenuName, setNestedMenuName] = React.useState("")
  const windowSize = useWindowSize()

  React.useEffect(() => {
    setFilteredCats(categories)
  }, [categories])

  if (windowSize?.width < 1024) return <></>
  const clearFromDate = (e: React.MouseEvent) => {
    e.stopPropagation()
    dateControls.handleFromDateChange(null)
  }
  const clearToDate = (e: React.MouseEvent) => {
    e.stopPropagation()
    dateControls.handleToDateChange(null)
  } 

  return (
    <Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
      {props.inside !== 'UserProfile' && 
      <MenuItem data-test-id='createdByMeId' selected={props.filterByMe} onClick={props.toggleFilterByMe}>
        Created by Me
      </MenuItem>}

      <MenuItem data-test-id='filterByFavId' selected={props.filterByFav} onClick={props.toggleFilterByFav}>Favourite</MenuItem>
     
      <SearchNestedMenu
        data-test-id='searchNestedMenu'
        name="category"
        nestedMenuName={nestedMenuName}
        setNestedMenuName={setNestedMenuName}
        items={categories}
        selectedIds={catControls.selectedCategories}
        label="Based on Category"
        selectHandler={catControls.handleSelect}
        parentMenuOpen={!!menuPosition}
      />

      <SearchNestedMenu
        name="subcategory"
        nestedMenuName={nestedMenuName}
        setNestedMenuName={setNestedMenuName}
        items={tags}
        selectedIds={tagControls.selectedTags}
        label="Based on Tags"
        selectHandler={tagControls.handleSelect}
        parentMenuOpen={!!menuPosition}
      />

      <NestedMenuItem
        data-test-id='nestedmenuId'
        name="date"
        nestedMenuName={nestedMenuName}
        setNestedMenuName={setNestedMenuName}
        keepOpen={keepOpen}
        label="Based on Date"
        parentMenuOpen={!!menuPosition}
        // @ts-ignore
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <FilterByDateWrapper>
          <FilterByDateRow>
            Choose Date
          </FilterByDateRow>
          <FilterByDateRow>
            <span style={{ flex: 1 }}>From</span>
            <div style={{ flex: 3 }}>
              {/* @ts-ignore  */}
              <DatePicker
                data-test-id='datePickerId'
                clearable
                variant="inline"
                inputVariant="outlined"
                onChange={dateControls.handleFromDateChange}
                value={dateControls.filterFromDate}
                autoOk={true}
                format="yyyy-MM-dd"
                style={{ width: '100%' }}
                InputProps={{
                  endAdornment: (<InputAdornment position="end">
                    {dateControls.filterFromDate && <CloseCircleIcon
                      onClick={clearFromDate}
                      style={{ width: '24px', cursor: 'pointer' }}
                    />}
                  </InputAdornment>)
                }}
                inputProps={{
                  placeholder: 'Select', onFocus: handleDateInputFocus,
                  onBlur: handleDateInputBlur,
                }}
                maxDate={new Date()}
                initialFocusedDate={dateControls.filterToDate}
              />

            </div>
          </FilterByDateRow>
          <FilterByDateRow>
            <span style={{ flex: 1 }}>To</span>
            <div style={{ flex: 3 }}>
              <DatePicker
                data-test-id='filterToDateId'
                clearable
                variant="inline"
                inputVariant="outlined"
                onChange={dateControls.handleToDateChange}
                value={dateControls.filterToDate}
                autoOk={true}
                format="yyyy-MM-dd"
                maxDate={new Date()}
                minDate={dateControls.filterFromDate || new Date()}
                initialFocusedDate={dateControls.filterFromDate}
                style={{ width: '100%' }}
                inputProps={{
                  placeholder: 'Select', onFocus: handleDateInputFocus,
                  onBlur: handleDateInputBlur
                }}
                // @ts-ignore
                InputProps={{
                  endAdornment: (<InputAdornment position="end">
                    {dateControls.filterToDate && <CloseCircleIcon
                      onClick={clearToDate}
                      style={{ width: '24px', cursor: 'pointer' }}
                    />}
                  </InputAdornment>)
                }}
              />

            </div>
          </FilterByDateRow>
          <FilterByDateRow>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 3 }}>
              <CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
              <SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
            </div>
          </FilterByDateRow>
        </FilterByDateWrapper>

      </NestedMenuItem>

    </Menu>
  )
}


interface SearchNestedMenuProps {
  items: any[];
  selectedIds: number[];
  selectHandler: (id: number) => (e: any) => void;
  label: string;
  parentMenuOpen: boolean;
  nestedMenuName?: any;
  setNestedMenuName?: any;
  name?: any;
}

export function SearchNestedMenu({ items, selectedIds, selectHandler, label, parentMenuOpen, name, nestedMenuName, setNestedMenuName }: SearchNestedMenuProps) {
  const [search, setSearch] = React.useState("")
  const [focus, setFocus] = React.useState(false)
  const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
  const classes = useNestedMenuStyles();
  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value
    setSearch(value)
  }

  React.useEffect(() => {
    setFilteredItems(items.filter(
      (item: any) => item.name.toLowerCase().includes(search.toLowerCase())
    )
    )
  }, [search])

  const stopPropagation = (e: React.KeyboardEvent<any>) => e.stopPropagation()
  return <NestedMenuItem
    name={name}
    nestedMenuName={nestedMenuName}
    setNestedMenuName={setNestedMenuName}
    keepOpen={focus}
    label={label}
    parentMenuOpen={parentMenuOpen}
    // @ts-ignore
    MenuProps={{
      classes: { paper: classes.menuPaper, list: classes.list },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      }
    }}
  >
    <li onKeyDown={stopPropagation} >
      <TextField
        data-test-id='searchFeeds'
        InputProps={{
          startAdornment: (<InputAdornment position="start">
            <img src={SearchIcon} width={20} style={{ paddingLeft: '15px' }} />
          </InputAdornment>
          ),
        }}
        fullWidth
        style={{ marginTop: '5px 10px' }}
        inputProps={{ style: { padding: '10px 10px' } }}
        onFocus={onFocus}
        onBlur={onBlur}
        value={search}
        onChange={onSearchChange}
        placeholder="Search" />
    </li>
    
    {items?.length === 0 && <div style={{ padding: '20px' }}>Loading</div>}
    {filteredItems?.length === 0 && <div style={{ padding: '20px' }}>No match found</div>}
    {
      filteredItems?.map(
        (cat: any) => (
          <ReverseMenuItem onClick={selectHandler(cat.id)} key={cat.id}>
            <Checkbox
              onChange={selectHandler(cat.id)}
              checked={selectedIds.indexOf(cat.id) !== -1}
              icon={icon}
              color="primary"
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
            />
            <span className={classes.menuItemLabel}>{cat.name}</span>
          </ReverseMenuItem>
        ))
    }
  </NestedMenuItem>
}




//*********** styles ***************//
const useNestedMenuStyles = makeStyles((theme: any) => ({
  menuPaper: {
    height: 300,
    display: 'flex',
    // Fix for scroll bug 
    // https://github.com/mui/material-ui/issues/10601#issuecomment-400479793
    pointerEvents: 'auto',
  },
  list: {
    width: 400,
    display: 'flex'
  },
  menuItemLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const ReverseMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  whiteSpace:'normal',
})

const FilterRowWrapper = styled('div')((props: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  width: '100%',
  gap: '5px',
  [props.theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > div': {
      width: '100%' // child with 100% width
    }
  }
}))

const TextFieldRounded = styled(TextField)({
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '15px',
  '& > .MuiOutlinedInput-root': {
    borderRadius: '15px',
  },
  '& > div > input': {
    paddingTop: '14px',
    paddingBottom: '14px'
  }
})

const PageWrapper = styled('div')((props: any) => ({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100%',
}))

const PostMainContent = styled('div')({
  padding: '10px 0',
  width: '100%'
})

const PostImage = styled('img')({
  width: '100%',
  borderRadius: '15px',
  margin: '20px 0',
  height: '350px'
})

const BtnImg = styled('img')({
  width: 25
})

const CenterRow = styled('div')((props: any) => ({
  width: '100%',
  marginTop: '30px',
  maxWidth: props.inside ? 'unset' : '900px'
}))

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const FlexSpaceBet = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
  flexWrap:'wrap'
})


const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  padding: '0 20px',
  flexWrap:'wrap'
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const NewPostCardWrapper = styled(RoundedCard)({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '15px',
  margin: '0'
})

const FilterByDateWrapper = styled('div')({
  width: '280px',
  height: '230px',
  padding: '10px 15px',
  background: '#FFF'
})

const FilterByDateRow = styled('div')({
  display: 'flex',
  marginBottom: '20px',
  alignItems: 'center'
})

const StyledPopper = styled(Popper)({
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginLeft:'20px',
  backgroundColor: "white",
  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
});

const SubmitButton = styled(Button)({
  background: '#009c20',
  color: '#FFF',
  '&:hover': {
    background: '#009c20',
    opacity: 0.9
  }
})

const CancelButton = styled(Button)({
  color: '#FFF',
  background: '#999',
  marginRight: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize'
})

const FeedCarousel = styled('div')({
  '& .img':{
    minWidth:'unset',
    '@media screen and (max-width:768px)':{
      width:'unset',
    }
  },
  '& .carousel .slide img':{
    width:'unset',
    '@media screen and (max-width:768px)':{
      width:'100%',
    }
  },
})

// Customizable Area End