import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  View,
  KeyboardAvoidingView
} from "react-native";
import { Link } from 'react-router-dom'
import { Box, Grid, Typography, Chip, IconButton, Button, TextField, FormControl, Select, MenuItem, Checkbox, ListItemText, InputAdornment } from '@material-ui/core';
import { image_Bitmap, CorpLogo, image_preference } from "./assets";
import Hidden from '@material-ui/core/Hidden';
import { Form, Formik, useField, useFormikContext } from 'formik'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { GoogleLogin } from 'react-google-login';
import { LinkedIn } from '../../../components/src/LinkedinLogin.web'
import MicrosoftLogin from "react-microsoft-login"
import { configJSON as loginConfig } from '../../email-account-login/src/EmailAccountLoginController'
import { googleLogin, microsoftLogin, linkedInLoginLogo } from '../../email-account-login/src/assets'
import OverlayLoader from '../../../components/src/Loader.web'
import { FormikSubmit } from "../../../components/src/FormModal.web";
import { GreenTickIcon } from './assets'
import { styled } from '@material-ui/styles';
import UserContext from "../../../components/src/UserProvider";
import Maps from "../../maps/src/Maps.web"
import AttachmentModal from '../../../components/src/AttachmentModal.web'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    //@ts-ignore
    const { navigation } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={{ overflowX: 'hidden' }}>
        <KeyboardAvoidingView
          behavior={this.isPlatformiOS() ? "padding" : undefined}
          style={styles.keyboardPadding}
        >
          {this.state.showTerms && <AttachmentModal
            onClose={() => this.setState({ showTerms: false })}
            url={this.state.termsUrl}
          />}


          <View>
            {/* <View style={styles.headline}>
                {this.isPlatformWeb() ? (
                  <Text style={styles.signUpText}>Sign Up</Text>
                ) : null}
                <Text style={styles.titleWhySignUp}>{this.labelHeader}</Text>
              </View> */}
            <Formik
              onSubmit={this.createSignUpAccount}
              validationSchema={this.validationSchema}
              initialValues={this.formInitialValues}>
              <Form noValidate translate="no" autoComplete="off">
                <InjectAPIErrors errors={this.state.apiErrors} />
                <SocialVerifedEffect socialValues={this.state.socialValues} />
                <Grid container spacing={1} style={{ fontFamily: 'helveticaneue' }} >
                  <Grid item md={6} lg={6}  >
                    <Hidden smDown>
                      <Box style={{ backgroundColor: "white", height: "100%", display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center", position: "relative", }}>
                        <img src={image_Bitmap} width="500px" height="400px" style={{ borderRadius: "10px", }} />

                      </Box>
                    </Hidden>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} >
                    <Box style={{ padding: "20px 40px 40px 40px", }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box style={{ display: 'flex', flexDirection: 'column', borderRadius: "10px", padding: "20px 20px 2px 20px", alignItems: "center", justifyContent: "center", position: "relative", }}>
                            <Link to="/">
                              <img src={CorpLogo} width="150px" height="50px" style={{ borderRadius: "10px", }} />
                            </Link>
                          </Box>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '40px' }}>
                          <Typography variant="h5" gutterBottom style={{ fontFamily: 'helveticaneue', fontWeight: "bold", textAlign: "center", marginBottom: "20px" }} component="div">
                            Sign Up
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <FormHeading variant="subtitle1" gutterBottom component="div">
                                First Name
                                <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                                  *
                                </Typography>
                              </FormHeading>
                              <FormikTextField
                                placeholder="Enter First Name"
                                name="first_name"
                                InputProps={{ disableUnderline: true }}
                              />
                              <InputRow />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <FormHeading variant="subtitle1" gutterBottom component="div">
                                Last Name
                                <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                                  *
                                </Typography>
                              </FormHeading>
                              <FormikTextField
                                placeholder="Enter Last Name"
                                name="last_name"
                                InputProps={{ disableUnderline: true }}
                              />
                              <InputRow />
                            </Grid>

                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <FormHeading variant="subtitle1" gutterBottom component="div">
                            Email
                            <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                              *
                            </Typography>
                          </FormHeading>
                          <FormikEmailField
                            disabled={!!this.state.socialEmail}
                            socialEmail={this.state.socialEmail}
                            resetSocialEmail={() => this.setState({ socialEmail: "" })}
                            placeholder="Enter Email ID"
                            type="email"
                            name="email"
                            id="email"
                            autoComplete='off'
                            autoFill='off'
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <DraftsOutlinedIcon style={{ color: "#c9c9c9", marginRight: "10px" }} />
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputRow>
                            <Typography
                              variant="subtitle2" gutterBottom
                              style={{
                                fontWeight: "lighter",
                                fontStyle: "italic",
                                color: "#5c5b5b",
                                margin: "5px 3px 10px 3px"
                              }}
                              component="div" >
                              {!this.state.socialEmail && "Verification OTP will be sent to the email id once user click on create account"}

                            </Typography>
                          </InputRow>

                        </Grid>
                        <Grid item xs={12} >
                              <FormHeading variant="subtitle1" gutterBottom component="div">
                                User Name
                                <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                                  *
                                </Typography>
                              </FormHeading>
                              <FormikTextField
                                placeholder="Enter User Name"
                                name="user_name"
                                InputProps={{ disableUnderline: true }}
                              />
                              <InputRow />
                            </Grid>
                        <Grid item xs={12}>
                          <InputRow >
                            <FormHeading variant="subtitle1" gutterBottom component="div">
                              Preferences
                              <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                                *
                              </Typography>
                            </FormHeading>
                            <PreferencesSelect allPreferences={this.state.allPreferences} name="preferences" />
                          </InputRow>
                        </Grid>
                        <Grid item xs={12}>
                          <InputRow >
                            <FormHeading variant="subtitle1" gutterBottom component="div">
                              Location
                              <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                                *
                              </Typography>
                            </FormHeading>
                            {/* @ts-ignore */}
                               <Maps {...this.props}
                              customBorder='1px solid #E0E0E0'
                              input
                            />
                          </InputRow>
                        </Grid>
                        <Grid item xs={12}>
                          <InputRow>
                            <FormHeading variant="subtitle1" gutterBottom component="div">
                              Password
                              <Typography variant="subtitle1" component="span" style={{ fontWeight: "bold" }}>
                                *
                              </Typography>
                            </FormHeading>
                            <FormikTextField
                              name="password"
                              id="password"
                              placeholder="Enter Password"
                              onCopy={this.handleChangeCopy}
                              onPaste={this.handleChangeCopy}
                              onCut={this.handleChangeCopy}
                              type={this.state.showPassword ? 'text' : 'password'}
                              autoComplete={this.state.showPassword ? 'off' : 'new-password'}
                              autoFill={this.state.showPassword ? 'off' : 'new-password'}
                              InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <HttpsOutlinedIcon style={{ color: "#c9c9c9", marginRight: "10px" }} />
                                ),
                                endAdornment: (
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {this.state.showPassword ? <VisibilityOffOutlinedIcon style={{ color: "#c9c9c9" }} /> : <VisibilityOutlinedIcon style={{ color: "#c9c9c9" }} />}
                                  </IconButton>

                                )
                              }}

                            />
                          </InputRow>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="error">
                            {this.state.formInlineError}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <InputRow style={{ display: "flex", alignItems: "center", }} >
                            <FormikCheckbox
                              goToTermsAndCondition={this.goToTermsAndCondition}
                              name="checkbox"
                            />

                          </InputRow>
                        </Grid>
                        <Grid item xs={12}>
                          <Box style={{ 
                            display: "flex", justifyContent: "center", alignItems: "center", margin: "20px 0px", }}>
                            <FormikSubmit component={Button} props={{
                              type: 'submit',
                              variant: 'contained',
                              color: 'primary',
                              style: {
                                width: '300px',
                                height: '50px',
                                textTransform: 'none'
                              }

                            }}>
                              Create Account
                            </FormikSubmit>
                            {/*                              <Button type="submit" variant="contained" disabled={this.state.isLoading} style={{ width: "300px", backgroundColor: "#1ea60a", color: "white", height: "50px", textTransform: 'none' }} >
                                {this.state.isLoading ? <CircularProgress style={{ color: "white" }} /> : "Create Account"}
                              </Button>*/}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                            <Typography
                              variant="subtitle2" gutterBottom
                              style={{
                                fontWeight: "normal",
                                color: "grey",
                                margin: "5px 3px"
                              }}
                              component="div" >
                              Already have an Account?
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom style={{fontFamily: 'HelveticaNeue', fontWeight: "bold", color: "grey", marginTop: "4px", cursor: "pointer" }} component="div" onClick={() => { navigation.navigate('Home') }} >
                              Login
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant="subtitle2" gutterBottom style={{fontFamily: 'HelveticaNeue', fontWeight: "bold", color: "grey", marginTop: "30px" }} component="div" >
                              Or Signup with
                            </Typography>

                          </Box>

                        </Grid>
                        <Grid item xs={12}>
                          <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                          }}>
                            <GoogleLogin
                              clientId={loginConfig.googleClientId}
                              render={renderProps => (
                                <div style={{
                                  padding: "5px",
                                  margin: "5px",
                                  cursor: 'pointer',
                                  position: 'relative',
                                  opacity: this.state.socialLoading === "google" ? "0.6" : "1"
                                }}
                                  onClick={() => {
                                    if (this.state.socialLoading) return;
                                    this.setState({ socialLoading: 'google' })
                                    renderProps.onClick()
                                  }}>
                                  <img src={googleLogin} width="40px" height="40px" />
                                </div>
                              )}
                              buttonText="Login"
                              onSuccess={this.handleGoogleLogin}
                              onFailure={() => this.setState({ socialLoading: "" })}
                              cookiePolicy={'single_host_origin'}
                            />
                            <MicrosoftLogin
                              useLocalStorageCache
                              withUserData
                              graphScopes={["user.read", "email"]}
                              clientId={loginConfig.microsoftClientID}
                              authCallback={this.handleMicrosoftLogin}>
                              <div style={{
                                padding: "5px",
                                margin: "5px",
                                cursor: 'pointer',
                                position: 'relative',
                                opacity: this.state.socialLoading === "microsoft" ? "0.6" : "1"
                              }}
                                onClick={() => this.setState({ socialLoading: "microsoft" })}
                              >
                                <img src={microsoftLogin} width="40px" height="40px" />
                              </div>
                            </MicrosoftLogin>
                            <LinkedIn
                              clientId={loginConfig.linkedinClientId}
                              redirectUri={`${window.location.origin}/LinkedinRedirect`}
                              onSuccess={(code: any) => {
                                this.handleLinkedinLogin(code);
                                console.log(code);
                              }}
                              onError={(error: any) => {
                                console.log(error);
                                this.setState({ socialLoading: "" })
                              }}
                            >
                              {({ linkedInLogin }) => (
                                <div onClick={() => { if (this.state.socialLoading) return; this.setState({ socialLoading: 'linkedin' }); linkedInLogin(); }} style={{ padding: "5px", margin: "5px", position: 'relative', cursor: 'pointer' }}>
                                  <img src={linkedInLoginLogo} width="40px" height="40px" alt="Sign in with Linked In" />
                                </div>
                              )}
                            </LinkedIn>


                          </Box>
                        </Grid>
                      </Grid>


                    </Box>
                  </Grid>

                </Grid>
                <OverlayLoader loading={!!(this.state.isLoading || this.state.socialLoading)} />

              </Form>
            </Formik>
          </View>
        </KeyboardAvoidingView>
      </div>
      // Customizable Area End
    );
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTermsAndCondition();
    console.log("term")
    this.loadPreferences();
  }
  // Customizable Area End
}

// Customizable Area Start

EmailAccountRegistrationController.contextType = UserContext
export const FormikTextField = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  return <>
    <TextField
      error={!!(meta.touched && meta.error)}
      {...field}
      {...props}
      style={{
        ...(props.style || {}),
      }}
      variant="outlined"
      fullWidth
    />
    <Typography variant="subtitle2" component='span' color="error">
      {(meta.touched && meta.error) ? meta.error : ' '}
    </Typography>
  </>
}

const FormikEmailField = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  const { socialEmail, resetSocialEmail, ...otherProps } = props
  React.useEffect(() => {
    socialEmail && helpers.setValue(socialEmail)
  }, [socialEmail])

  const onChange = (e: any) => {
    if (props.socialEmail)
      resetSocialEmail && resetSocialEmail()
    helpers.setValue(e.target.value)
  }

  return <>
    <TextField
      error={!!(meta.touched && meta.error)}
      {...field}
      {...otherProps}
      onChange={onChange}
      InputProps={{
        endAdornment: <InputAdornment position="end"> {props.socialEmail && <VerifiedBadge />}</InputAdornment>,
        disableUnderline: true
      }}
      variant="outlined"
      fullWidth
    />
    <Typography variant="subtitle2" component='span' color="error">
      {(meta.touched && meta.error) ? meta.error : ' '}
    </Typography>
  </>
}

const FormikCheckbox = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  return <div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        {...field}
        style={{ color: "#1ea60a", fontSize: 10 }}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Typography variant="subtitle2" style={{
        fontWeight: "normal",
        color: "grey",
        margin: "5px 3px"
      }}>I Accept</Typography>
      <Typography color="primary" onClick={props.goToTermsAndCondition}
        variant="subtitle2" gutterBottom
        style={{
          textDecoration: 'underline',
          fontWeight: "normal",
          margin: "5px 3px",
          cursor: 'pointer',
        }}
        component="div" >
        Terms & Conditions
      </Typography>
    </div>
    <Typography variant="subtitle2" component='span' color="error">{meta.touched && meta.error}</Typography>
  </div>
}

const PreferencesSelect = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  const handleDelete = (preference: string) => () => {
    helpers.setValue(field.value.filter((x: any) => x !== preference))
  }

  return <><FormControl variant="outlined" fullWidth>
    <Select
      {...field}
      labelId="demo-mutiple-checkbox-label"
      id="demo-mutiple-checkbox"
      multiple
      displayEmpty
      error={!!(meta.touched && meta.error)}
      renderValue={(selected: any) => {
        return <div style={{ display: "flex" }}>
          <img src={image_preference} width="20px" height="15px" style={{ borderRadius: "10px", paddingTop: "4px", marginRight: "5px" }} />
          <Typography>
            Select
          </Typography>
        </div>;
      }}
      // @ts-ignore
      MenuProps={MenuProps}
    >
      {props.allPreferences.map((p: any, idx: number) => (
        <MenuItem style={{
          height: "50px",
          display: "flex",
          padding: "20px 20px",
          backgroundColor:idx % 2 == 0 ? "#40FF0035" : "#FFFFFF",
        }} key="E" value={p.id}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#000" }} />}
            checked={field.value.indexOf(p.id) > -1}
          />
          <ListItemText disableTypography primary={
            <div style={{ display: "flex", color: "#000" }}>
              <Typography style={{ marginRight: '120px' }}>
                {p.name.slice(0, 1)}
              </Typography>
              <Typography>
                #{p.name}
              </Typography>
              <Typography>
              </Typography>
            </div>
          }
          />
        </MenuItem>   
      ))}

    </Select>

  </FormControl>
    <Typography variant="subtitle2" component='span' color="error">
      {meta.touched && meta.error}
    </Typography>
    {
      field?.value?.map?.((preference: any) => (
        <Chip
          key={preference}
          label={props.allPreferences.find((p: any) => p.id === preference)?.name || ''}
          onDelete={handleDelete(preference)}
          style={{ margin: "5px", color: "rgb(30, 166, 10)" }}
        />
      ))
    }
  </>
}


const FormHeading = styled(Typography)({
  fontFamily: 'helveticaneue',
  color: '#404040',
  fontWeight: 500
}) as typeof Typography

const InputRow = styled(Box)({
  marginBottom: '15px'
})

const VerifiedBadge = () => {
  return <VBWrapper><img width="15px" height="15px" src={GreenTickIcon} /> <Typography style={{ fontSize: '13px', fontWeight: 700 }} color="primary">Verified</Typography></VBWrapper>
}


// set field values from social API
const SocialVerifedEffect = (props: any) => {
  const { setFieldValue, ...formik } = useFormikContext()
  React.useEffect(() => {
    if (props.socialValues)
      props.socialValues.map((sv: any) => {
        setFieldValue(sv[0], sv[1])
      })
  }, [props.socialValues])
  return <></>
}

const InjectAPIErrors = (props: any) => {
  const { setFieldError, ...formik } = useFormikContext()
  React.useEffect(() => {
    if (!props.errors) return;
    props.errors.forEach((error: Object) => {
      const field = Object.keys(error)[0]
      setFieldError(field, Object.values(error)[0])
    })
  }, [props.errors])
  return <></>
}

const VBWrapper = styled('div')({
  backgroundColor: '#DDEFDA',
  borderRadius: '20px',
  padding: '8px 12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px'
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    display: "flex",
    width: "100%",
  },

  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  fontBold: {
    fontWeight: "bold",
  }
});


// Customizable Area End
